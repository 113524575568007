import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

// image

import logo from '../assets/images/nav-v2-logo.png'
import logo02 from '../assets/images/logo.png'
import footerlogo from '../assets/images/svg-logo.png'
import footerImg01 from '../assets/images/footer-img-1.png'
import footerImg02 from '../assets/images/footer-img-2.png'
import footerImg03 from '../assets/images/footer-img-3.png'

import headerblogcat from '../assets/images/my-banner-v4-background.jpg'
import cardback from '../assets/images/card-background.png'
import bannerv4 from '../assets/images/bannre-v4-card-img.png'
import blogcat from '../assets/images/blog-category-box-img.png'

import { IoMdMenu } from 'react-icons/io'
import { FaRegWindowClose } from 'react-icons/fa'
import { CiLogin } from 'react-icons/ci'
import { RiShoppingBasketFill } from 'react-icons/ri'

function BlogCategory() {
  const [posts, setPosts] = useState([])
  const navigate = useNavigate()

  // Fetch data from API
  useEffect(() => {
    const fetchPosts = async () => {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://steelblue-ferret-758809.hostingersite.com/api/blog/posts',
        headers: {},
      }

      try {
        const response = await axios.request(config)
        if (response.data && response.data.is_status) {
          setPosts(response.data.data.posts)
        }
      } catch (error) {
        console.log('Error fetching blog posts:', error)
      }
    }

    fetchPosts()
  }, [])

    const handlePostClick = (id) => {
      navigate(`/blog/${id}`)
    }


  return (
    <div className='body-white-color-2'>
      {/* <!--header-top--> */}
      <div className='header-top-main-wrapper'>
        {/* <!--input-for-controlling-the-functionality--> */}
        <input
          type='checkbox'
          className='mobile-nav-input mobile-nav-input-v2'
          id='mobile-nav-check'
          hidden
        />
        <div className='header-top-wrapper'>
          {/* <!--setting-contaienr--> */}
          <div className='container'>
            {/* <!--navigation--> */}
            <nav className='navigation-v2'>
              {/* <!--logo--> */}
              <a href='./index-1.html' className='nav-v2-logo-wrapper'>
                {/* <!--img--> */}
                <img src={logo} alt='Logo' className='nav-v2-logo' />
              </a>
              {/* <!--list--> */}
              <ul className='nav-v2-list'>
                {/* <!--item--> */}
                <li className='nav-v2-item'>
                  <a href='./product-page.html' className='nav-v2-item-link'>
                    لیست گیفت کارت
                  </a>
                </li>
                {/* <!--item--> */}
                <li className='nav-v2-item'>
                  <a href='./teach-page.html' className='nav-v2-item-link'>
                    آموزش استفاده گیفت کارت
                  </a>
                </li>
                {/* <!--item--> */}
                <li className='nav-v2-item'>
                  <a href='./blog.html' className='nav-v2-item-link'>
                    بلاگ
                  </a>
                </li>
                {/* <!--item--> */}
                <li className='nav-v2-item'>
                  <a href='./error-404.html' className='nav-v2-item-link'>
                    تماس با ما
                  </a>
                </li>
              </ul>
              {/* <!--==mobile-nav==--> */}
              {/* <!--open-icon--> */}
              <label
                for='mobile-nav-check'
                className='mobile-nav-open-label mobile-nav-btns'
              >
                <IoMdMenu className='fa-solid fa-bars open-nav-icon' />
              </label>

              {/* <!--buttons--> */}
              <div className='nav-v2-links-wrapper'>
                {/* <!--link(Shop-link)--> */}
                <a
                  href='./shop-basket-factor.html'
                  className='nav-v2-link nav-v2-shop-link'
                >
                  {/* <!--icon--> */}
                  <i className='fa-solid fa-basket-shopping'></i>
                  <RiShoppingBasketFill className='fa-solid fa-basket-shopping' />
                </a>
                {/* <!--link(login-link)--> */}
                <a
                  href='./login.html'
                  className='nav-v2-link nav-v2-Login-link'
                >
                  {/* <!--text-or-icon--> */}
                  ورود به حساب
                </a>
                {/* <!--login--> */}
                <a
                  href='./login.html'
                  className='nav-login-btn nav-v2-responsive-login'
                >
                  <span className='login-text'>ورود</span>

                  <CiLogin className='fa-solid fa-arrow-right-to-bracket nav-login-icon' />
                </a>
              </div>
            </nav>
          </div>
        </div>
        {/* <!--nav-list--> */}
        <ul className='mobile-nav-list'>
          {/* <!--close-icon--> */}
          <label
            for='mobile-nav-check'
            className='mobile-nav-close-label mobile-nav-btns'
          >
            <FaRegWindowClose className='fa-solid fa-x open-close-icon' />
          </label>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./index-2.html' className='mobile-nav-link'>
              خانه
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./product-page.html' className='mobile-nav-link'>
              گیفت کارت
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./teach-page.html' className='mobile-nav-link'>
              آموزش استفاده گیفت کارت
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./error-404.html' className='mobile-nav-link'>
              راهنمای خرید گیفت کارت
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./blog.html' className='mobile-nav-link'>
              بلاگ
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./error-404.html' className='mobile-nav-link'>
              تماس با ما
            </a>
          </li>
        </ul>
      </div>

      {/* <!--=======Header============--> */}
      <header className='header-v2' id='header-v2'>
        {/* <!--setting-container--> */}
        <div className='container'>
          {/* <!--banner--> */}
          <div className='banner-v4'>
            {/* <!--right--> */}
            <div className='banner-v4-right-wrapper slider-y'>
              {/* <!--main-banner--> */}
              <div className='banner-v4-main-banner'>
                {/* <!--background-img--> */}
                <img
                  src={headerblogcat}
                  alt='img'
                  className='banner-v4-main-background'
                />
                {/* <!--banner-content-box--> */}
                <div className='banner-v4-main-content-box'>
                  {/* <!--title--> */}
                  <h3 className='banner-v4-content-title'>
                    بهترین dns برای پلی استیشن 5
                  </h3>
                  {/* <!--wrapper--> */}
                  <div className='banner-v4-content-bottom-wrapper'>
                    {/* <!--right--> */}
                    <div className='banner-v4-content-bottom-right'>
                      {/* <!--text--> */}
                      <p className='banner-v4-content-text'>
                        {/* <!--icon--> */}
                        <i className='fa-solid fa-layer-group banner-v4-content-icon'></i>
                        دسته بندی:
                        <span className='banner-v4-content-span'>آموزش</span>
                      </p>
                      {/* <!--text--> */}
                      <p className='banner-v4-content-text'>
                        {/* <!--icon--> */}
                        <i className='fa-solid fa-clock banner-v4-content-icon'></i>
                        مدت زمان مطالعه:
                        <span className='banner-v4-content-span'>10 دقیقه</span>
                      </p>
                    </div>
                    {/* <!--left--> */}
                    <div className='banner-v4-content-bottom-left'>
                      {/* <!--sound-line--> */}
                      <span className='sound-stroke'></span>
                      <span className='sound-stroke'></span>
                      <span className='sound-stroke'></span>
                      <span className='sound-stroke'></span>
                    </div>
                  </div>
                </div>
                {/* <!--mobile-stroks--> */}
                <div className='banner-v4-content-bottom-left mobile-sound-strokes'>
                  {/* <!--sound-line--> */}
                  <span className='sound-stroke'></span>
                  <span className='sound-stroke'></span>
                  <span className='sound-stroke'></span>
                  <span className='sound-stroke'></span>
                </div>
                {/* <!--==========buttons=======--> */}
                <div className='banner-v4-buttons'>
                  {/* <!--up-buttons--> */}
                  <button className='banner-v4-up-button banner-v4-button'>
                    {/* <!--icon--> */}
                    <i className='fa-solid fa-chevron-up banner-v4-up-button-icon'></i>
                  </button>
                  <span className='buttons-dot'>.</span>
                  <span className='buttons-dot'>.</span>
                  <span className='buttons-dot'>.</span>
                  {/* <!--down-buttons--> */}
                  <button className='banner-v4-down-button banner-v4-button'>
                    {/* <!--icon--> */}
                    <i className='fa-solid fa-chevron-down banner-v4-btn-icon banner-v4-up-button-icon'></i>
                  </button>
                </div>
              </div>
              {/* <!--main-banner--> */}
              <div className='banner-v4-main-banner'>
                {/* <!--background-img--> */}
                <img
                  src={headerblogcat}
                  alt='img'
                  className='banner-v4-main-background'
                />
                {/* <!--banner-content-box--> */}
                <div className='banner-v4-main-content-box'>
                  {/* <!--title--> */}
                  <h3 className='banner-v4-content-title'>
                    بهترین dns برای پلی استیشن 5
                  </h3>
                  {/* <!--wrapper--> */}
                  <div className='banner-v4-content-bottom-wrapper'>
                    {/* <!--right--> */}
                    <div className='banner-v4-content-bottom-right'>
                      {/* <!--text--> */}
                      <p className='banner-v4-content-text'>
                        {/* <!--icon--> */}
                        <i className='fa-solid fa-layer-group banner-v4-content-icon'></i>
                        دسته بندی:
                        <span className='banner-v4-content-span'>آموزش</span>
                      </p>
                      {/* <!--text--> */}
                      <p className='banner-v4-content-text'>
                        {/* <!--icon--> */}
                        <i className='fa-solid fa-clock banner-v4-content-icon'></i>
                        مدت زمان مطالعه:
                        <span className='banner-v4-content-span'>10 دقیقه</span>
                      </p>
                    </div>
                    {/* <!--left--> */}
                    <div className='banner-v4-content-bottom-left'>
                      {/* <!--sound-line--> */}
                      <span className='sound-stroke'></span>
                      <span className='sound-stroke'></span>
                      <span className='sound-stroke'></span>
                      <span className='sound-stroke'></span>
                    </div>
                  </div>
                </div>
                {/* <!--mobile-stroks--> */}
                <div className='banner-v4-content-bottom-left mobile-sound-strokes'>
                  {/* <!--sound-line--> */}
                  <span className='sound-stroke'></span>
                  <span className='sound-stroke'></span>
                  <span className='sound-stroke'></span>
                  <span className='sound-stroke'></span>
                </div>
                {/* <!--==========buttons=======--> */}
                <div className='banner-v4-buttons'>
                  {/* <!--up-buttons--> */}
                  <button className='banner-v4-up-button banner-v4-button'>
                    {/* <!--icon--> */}
                    <i className='fa-solid fa-chevron-up banner-v4-up-button-icon'></i>
                  </button>
                  <span className='buttons-dot'>.</span>
                  <span className='buttons-dot'>.</span>
                  <span className='buttons-dot'>.</span>
                  {/* <!--down-buttons--> */}
                  <button className='banner-v4-down-button banner-v4-button'>
                    {/* <!--icon--> */}
                    <i className='fa-solid fa-chevron-down banner-v4-btn-icon banner-v4-up-button-icon'></i>
                  </button>
                </div>
              </div>
            </div>
            {/* <!--left--> */}
            <div className='banner-v4-left-wrapper'>
              {/* <!--card--> */}
              <a href='./product-page-2.html' className='card-link'>
                <div className='card blog-category-banner-card'>
                  {/* <!--background-image--> */}
                  <img
                    src={cardback}
                    alt='background-img'
                    className='card-background-img'
                  />
                  {/* <!--content-wrapper--> */}
                  <div className='card-content'>
                    {/* <!--img--> */}
                    <img
                      src={bannerv4}
                      alt='gift-card-type'
                      className='card-img blog-category-card-img'
                    />
                    {/* <!--bottom-box--> */}
                    <div className='card-top-text-box'>
                      {/* <!--text--> */}
                      <p className='card-bottom-text card-banner-v4-top-text'>
                        {/* <!--icon--> */}
                        <i className='fa-solid fa-layer-group'></i>
                        آموزش
                      </p>
                      {/* <!--text--> */}
                      <p className='card-bottom-text card-banner-v4-top-text'>
                        {/* <!--icon--> */}
                        <i className='fa-brands fa-youtube'></i>
                        ویدیو | عکس
                      </p>
                    </div>
                    {/* <!--text--> */}
                    <h4 className='card-text'>
                      {/* <!--title--> */}
                      <span className='card-v4-title'>
                        آموزش وارد کردن انواع گیفت کارت
                      </span>
                    </h4>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </header>

      {/* <!---================Main==============--> */}
      <main className='main blog-category-page-main'>
        {/* <!---===========blog-category-section==========--> */}
        <section className='blog-category-section'>
          {/* <!--setting-contaienr--> */}
          <div className='container'>
            {/* <!--Main-wrapper---> */}
            <div className='blog-category-content-wrapper'>
              {/* <!--top-wrapper--> */}
              <div className='blog-category-top'>
                {/* <!--right-side--> */}
                <div className='blog-category-top-right-side'>
                  {/* <!--blog-category-text--> */}
                  <p className='blog-category-sub-text'>
                    {/* <!--icon--> */}
                    <i className='fa-solid fa-layer-group blog-category-sub-text-icon'></i>
                    دسته بندی
                  </p>
                  {/* <!--blog-button--> */}
                  <button className='blog-category-archive-button'>
                    <span className='blog-category-btn-text'>آموزش</span>
                    <span className='blog-category-btn-sub-text'>(10)</span>
                  </button>
                  {/* <!--blog-button--> */}
                  <button className='blog-category-archive-button'>
                    <span className='blog-category-btn-text'>آموزش</span>
                    <span className='blog-category-btn-sub-text'>(10)</span>
                  </button>
                </div>
                {/* <!--left-side--> */}
                <div className='blog-category-top-left-side'>
                  {/* <!--search-box--> */}
                  <div className='search-box-v1'>
                    {/* <!--input--> */}
                    <input
                      type='search'
                      class='search-box-v1-input'
                      placeholder='جستجوی آموزش ، مقالات ...'
                    />
                    {/* <!--icon--> */}
                    <i className='fa-solid fa-magnifying-glass search-box-v1-icon'></i>
                  </div>
                </div>
              </div>
              {/* <!--bottom-wrapper--> */}
              <div className='blog-category-bottom grid-3-columns-wrapper'>
                {/* <!--blog-box--> */}
                {posts.map((post) => (
                  <div
                    key={post.id}
                    href='/'
                    className='blog-category-box-link'
                    onClick={() => handlePostClick(post.id)}
                  >
                    <div className='blog-category-box'>
                      <img
                        src={post.image || 'default-image-url'} // Use default image if post.image is empty
                        alt='img'
                        className='blog-category-box-img'
                      />
                      <div className='blog-category-box-top-wrapper'>
                        <p className='blog-category-box-name '>
                          <i className='fa-solid fa-layer-group blog-category-box-icon'></i>
                          آموزش
                        </p>
                        <p className='blog-category-box-text'>
                          <i className='fa-solid fa-clock'></i>
                          مدت زمان مطالعه : 10 دقیقه
                        </p>
                      </div>
                      <h3 className='h4-title blog-category-box-title'>
                        {post.title}
                      </h3>
                      {/* <p className='blog-category-box-text blog-category-box-text-2'>
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                        چاپ و با استفاده از طراحان گرافیک است
                      </p> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <!--Pagination--> */}
            <div className='pagination-wrapper'>
              {/* <!--list--> */}
              <ul className='pagination-list'>
                {/* <!--item--> */}
                <li className='pagination-item'>1</li>
                {/* <!--item--> */}
                <li className='pagination-item blog-category-active-page'>2</li>
                {/* <!--item--> */}
                <li className='pagination-item'>3</li>
                {/* <!--item--> */}
                <li className='pagination-item'>4</li>
                {/* <!--item--> */}
                <li className='pagination-item'>5</li>
                {/* <!--item--> */}
                <li className='pagination-item'>6</li>
              </ul>
            </div>
          </div>
        </section>

        {/* <!--=============text-box-section============--> */}
        <section className='info-section-v3'>
          {/* <!--setting-container--> */}
          <div className='container'>
            {/* <!--info-box--> */}
            <div className='info-box-v3'>
              {/* <!--title--> */}
              <h4 className='h4-title'>پلی اسیتشن :</h4>
              {/* <!--text--> */}
              <p className='paragraph info-box-text'>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد
                نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد
                کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان
                جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را
                برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در
                زبان فارسی ایجاد کرد در این صورت می توان امید داشت که تمام و
                دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد
                نولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی
                می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت
                فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری
                را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو
                در زبان فارسی ایجاد کرد در این صورت می توان امید داشت که تمام و
                دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد
              </p>
              {/* <!--button--> */}
              <button className='info-section-button'>
                نمایش بیشتر
                {/* <!--icon--> */}
                <i className='fa-solid fa-chevron-down info-btn-icon'></i>
              </button>
            </div>
          </div>
        </section>
      </main>

      {/* <!--=========Footer==============--> */}
      <footer className='footer-v2 '>
        {/* <!--logo--> */}
        <img src={footerlogo} alt='logo' className='footer-logo' />
        {/* <!--===setting-container===--> */}
        <div className='bigger-container'>
          {/* <!--footer-content-wrapper--> */}
          <div className='footer-content-wrapper-v2'>
            {/* <!--footer-top-wrapper--> */}
            <div className='footer-top-wrapper-v2'>
              {/* <!--right-side--> */}
              <div className='footer-top-right-wrapper-v2'>
                {/* <!--title--> */}
                <h5 className='footer-row-title footer-row-title-v2'>
                  خدمات ما
                </h5>
                <div className='footer-list-wrapper-v2'>
                  {/* <!--list--> */}
                  <ul className='footer-list footer-list-v2'>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید گیفت کارت اپل
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید گیفت کارت پلی استیشن
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید گیفت کارت استیم{' '}
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید گیفت کارت ایکس باکس
                      </a>
                    </li>
                  </ul>
                  {/* <!--list--> */}
                  <ul className='footer-list footer-list-v2'>
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید گیفت کارت گوگل پلی
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید گیفت کارت پلی استیشن پلاس
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید یوسی پابجی{' '}
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید جم کلش اف کلنز
                      </a>
                    </li>
                  </ul>
                  {/* <!--list--> */}
                  <ul className='footer-list footer-list-v2'>
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید اشتراک پریمیوم تلگرام
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید اشتراک اسپاتیفای
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید لایسنس ادوبی{' '}
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید ویباکس فورتنایت
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='footer-top-left-wrapper-v2'>
                {/* <!--title--> */}
                <h5 className='footer-row-title footer-row-title-v2'>
                  دسترسی سریع
                </h5>
                <div className='footer-list-wrapper-v2'>
                  {/* <!--list--> */}
                  <ul className='footer-list footer-list-v2'>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./blog.html' className='footer-item-link'>
                        بلاگ زود گیفت{' '}
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        شرایط و قوانین خرید{' '}
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        حساب کاربری
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        تماس با ما
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!--footer-middle-wrapper--> */}
            <div className='footer-middle-wrapper-v2'>
              {/* <!--phone-number-box--> */}
              <div className='footer-phone-number-box'>
                {/* <!--title--> */}
                <h5 className='footer-row-title'>اطلاعات تماس</h5>
                {/* <!--footer-middle-text--> */}
                <p className='footer-middle-text'>
                  <a href='./error-404.html' className='middle-text-link'>
                    ۰۶۱۵۲۳۳۳۷۹۴
                  </a>{' '}
                  |{' '}
                  <a href='./error-404.html' className='middle-text-link'>
                    ۰۹۱۶۰۲۶۵۶۶۱
                  </a>
                </p>
              </div>
              {/* <!--socila-box--> */}
              <div className='footer-social-box'>
                {/* <!--title--> */}
                <h5 className='footer-row-title'>زود گیفت در شبکه اجتماعی</h5>
                {/* <!--footer-middle-text--> */}
                <p className='footer-middle-text-social'>
                  <a href='./error-404.html' className='middle-text-link'>
                    تلگرام
                  </a>
                  <a href='./error-404.html' className='middle-text-link'>
                    اینستاگرام
                  </a>
                  <a href='./error-404.html' className='middle-text-link'>
                    یوتیوب
                  </a>
                </p>
              </div>
            </div>
            {/* <!--footer-bottom-wrapper--> */}
            <div className='footer-bottom-wrapper'>
              {/* <!--footer-row--> */}
              <div className='footer-row footer-big-row-v2'>
                {/* <!--title--> */}
                <h5 className='footer-row-title-v2 footer-row-title'>
                  تجربه‌ای متفاوت در خرید گیفت کارت
                </h5>
                {/* <!--text--> */}
                <p className='footer-text footer-text-v2'>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                  با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و
                  مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی
                  تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای
                  کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و
                  آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم
                  افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                  طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد
                </p>
              </div>
              {/* <!--footer-row--> */}
              <div className='footer-row footer-logo-row footer-logo-row-v2'>
                {/* <!--title--> */}
                <h5 className='footer-row-title'>نماد اعتماد </h5>
                {/* <!--imgs-wrapper--> */}
                <div className='footer-row-img-wrapper'>
                  {/* <!--img-box--> */}
                  <div className='footer-img-box'>
                    {/* <!--img--> */}
                    <img
                      src={footerImg01}
                      alt='footer-img'
                      className='footer-img'
                    />
                  </div>
                  {/* <!--img-box--> */}
                  <div className='footer-img-box'>
                    {/* <!--img--> */}
                    <img
                      src={footerImg02}
                      alt='footer-img'
                      className='footer-img'
                    />
                  </div>
                  {/* <!--img-box--> */}
                  <div className='footer-img-box'>
                    {/* <!--img--> */}
                    <img
                      src={footerImg03}
                      alt='footer-img'
                      className='footer-img'
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <!--copy-right-wrapper--> */}
            <div className='copy-right-wrapper-v2'>
              {/* <!--right-side--> */}
              <p className='copy-right-right-text'>
                {/* <!--button--> */}
                <a href='#header-v2' className='moving-top-button'>
                  {/* <!--icon--> */}
                  <i className='fa-solid fa-arrow-up moving-top-icon'></i>
                </a>
                کلیه حقوق مادی و معنوی این سایت متعلق به فروشگاه زود گیفت
                می‌باشد
              </p>
              {/* <!--left-side--> */}
              <div className='copy-right-left-side-wrapper'>
                {/* <!--logo-wrapper--> */}
                <div className='copy-right-logo-wrapper'>
                  <img src={logo02} alt='logo' className='copy-right-logo' />
                </div>
                {/* <!--text-box---> */}
                <div className='copy-right-left-side-text-box'>
                  {/* <!--text--> */}
                  <p className='copy-right-left-main-text'>
                    © 2024 ZOODGIFT.COM
                  </p>
                  {/* <!--text--> */}
                  <p className='copy-right-left-sub-text'>
                    All material and intellectual rights of this site belong to
                    ZoodGift store
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default BlogCategory
