import React, { useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom' // استفاده از useNavigate
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

import loginimg from '../assets/images/form-banner-img.png'

function Register() {
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const navigate = useNavigate() // ایجاد نمونه از useNavigate

  const registerHandle = (event) => {
    event.preventDefault()

    if (password !== confirmPassword) {
      toast.error('رمز عبور و تکرار آن باید یکسان باشند', {
        className: 'toast-right',
      })
      return
    }

    const data = JSON.stringify({
      phone: phone,
      email: email,
      password: password,
    })

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://steelblue-ferret-758809.hostingersite.com/api/register',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios
      .request(config)
      .then((response) => {
        if (response.data.statusCode === 200) {
          toast.success('با موفقیت ثبت نام شدید', {
            className: 'toast-right',
            onClose: () => navigate('/login'), // هدایت به صفحه ورود پس از نمایش toast
          })
        } else {
          toast.error(response.data.message || 'خطایی رخ داده است', {
            className: 'toast-right',
          })
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || 'خطایی رخ داده است'
        toast.error(errorMessage, { className: 'toast-right' })
        console.log(error)
      })
  }

  return (
    <div className='body-white-color-2'>
      {/* <!--setting-container--> */}
      <div className='container'>
        {/* <!--content-wrapper--> */}
        <div className='section-flex-wrapper register-section-wrapper'>
          <div className='form-v1-wrapper register-form-v1-wrapper'>
            {/* <!--form--> */}
            <form
              className='form-v1 register-form-v1'
              onSubmit={registerHandle}
            >
              {/* <!--wrapper--> */}
              <div className='form-group-v1-wrapper'>
                {/* <!--form-grup(phone or email)--> */}
                <div className='form-group-v1 register-form-group'>
                  {/* <!--title--> */}
                  <h4 className='form-group-v1-title'>شماره موبایل</h4>
                  <input
                    type='text'
                    className='form-group-v1-input'
                    required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                {/* <!--form-grup(phone or email)--> */}
                <div className='form-group-v1 register-form-group register-form-input'>
                  {/* <!--title--> */}
                  <h4 className='form-group-v1-title'>ایمیل</h4>
                  <input
                    type='email'
                    name='email'
                    className='form-group-v1-input register-form-input'
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              {/* <!--wrapper--> */}
              <div className='form-group-v1-wrapper'>
                {/* <!--form-grup(password)--> */}
                <div className='form-group-v1'>
                  {/* <!--title--> */}
                  <h4 className='form-group-v1-title'>رمز عبور</h4>
                  <input
                    type='password'
                    className='form-group-v1-input form-group-v1-password'
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {/* <!--icon--> */}
                  <i className='fa-regular fa-eye form-v1-eye-icon'></i>
                </div>
                {/* <!--form-grup(password)--> */}
                <div className='form-group-v1'>
                  {/* <!--title--> */}
                  <h4 className='form-group-v1-title'>تکرار رمز عبور</h4>
                  <input
                    type='password'
                    className='form-group-v1-input form-group-v1-password'
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {/* <!--icon--> */}
                  <i className='fa-regular fa-eye form-v1-eye-icon'></i>
                </div>
              </div>

              {/* <!--form-link-wrapper--> */}
              <div className='form-v1-link-wrapper'>
                {/* <!--remmeber-me-wrapper--> */}
                <div className='remmeber-me'>
                  <input
                    type='checkbox'
                    id='remmeber-input'
                    className='remmeber-me-input'
                  />
                  {/* <!---text--> */}
                  <label htmlFor='remmeber-input' className='remmeber-me-text'>
                    من را به خاطر بسپار
                  </label>
                </div>
                {/* <!--forgot-link--> */}
                <a href='./login.html' className='form-forgot-link'>
                  حساب کاربری دارید؟
                </a>
              </div>
              <button type='submit' className='form-v1-btn'>
                ثبت نام
              </button>
              {/* <!--text--> */}
              <p className='form-v1-text'>
                {/* <!--icon--> */}
                <i className='fa-solid fa-circle-exclamation form-v1-text-icon'></i>
                با ورود به ام ار ام قوانین و شرایط استفاده از ام ار ام را
                می‌پذیرم.
              </p>
              {/* <!--text--> */}
              <p className='form-v1-text'>
                {/* <!--icon--> */}
                <i className='fa-solid fa-circle-exclamation form-v1-text-icon'></i>
                لطفا شماره موبایل که وارد می‌کنید به نام خودتان باشد
              </p>
            </form>
            {/* <!--buttom-links-wrapper--> */}
            <div className='form-v1-bottom-links-wrapper'>
              {/* <!--home-link--> */}
              <a href='./index-2.html' className='form-v1-home-link'>
                {/* <!--icon--> */}
                <i className='fa-solid fa-house'></i>
                بازگشت به فروشگاه
              </a>
              {/* <!--text--> */}
              <p className='form-v1-text form-v1-text-bottom'>
                مطمئن شوید که در دامنه mrm.gift هستید.
              </p>
            </div>
          </div>
          {/* <!--banner--> */}
          <div className='form-banner-v1'>
            {/* <!--img--> */}
            <img src={loginimg} alt='img' className='form-banner-v1-content' />
          </div>
        </div>
      </div>
      <ToastContainer toastClassName='toast-right' />
    </div>
  )
}

export default Register
