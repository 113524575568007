import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

import logo from '../assets/images/nav-v2-logo.png'
import blogHeroimg from '../assets/images/blog-hero.png'
import svglogo from '../assets/images/svg-logo.png'

import buygiftcart from '../assets/images/buy-gift-card-img.png'
import articleboximg01 from '../assets/images/article-box-img-1.png'
import giftbackimg from '../assets/images/card-background.png'
import cardcontentimg from '../assets/images/card-img-1.png'
import writerProfileImg from '../assets/images/writer-profie-img.png'
import videoBannerImg from '../assets/images/video-banner.png'
import playBackgroundIcon from '../assets/images/play-background-icon.png'
import videoSrc from '../assets/video/Mountain Golem Awakens ⛰️ Clash of Clans Official.mp4'
import blogInfoImg from '../assets/images/blog-info-img.png'
import tickSquare from '../assets/images/tick-square.png'
import logopng from '../assets/images/logo.png'

import otherArticleImg3 from '../assets/images/other-article-img-3.png'
import otherArticleImg2 from '../assets/images/other-article-img-2.png'
import otherArticleImg1 from '../assets/images/other-article-img-1.png'
// icon

import { IoMdMenu } from 'react-icons/io'
import { FaRegWindowClose } from 'react-icons/fa'
import { CiLogin } from 'react-icons/ci'
import { RiShoppingBasketFill } from 'react-icons/ri'

function Blog() {
  const { id } = useParams()
  const [post, setPost] = useState(null)

  useEffect(() => {
    const fetchPost = async () => {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://api.zoodgift.com/api/blog/posts/${id}`,
        headers: {},
      }

      try {
        const response = await axios.request(config)
        setPost(response.data.data)
      } catch (error) {
        console.log('Error fetching post:', error)
      }
    }

    fetchPost()
  }, [id])

  if (!post) {
    return <p>Loading...</p>
  }

  return (
    <div className='page-main-container body-white-color-2'>
      {/* <!--header-top--> */}
      <div className='header-top-main-wrapper'>
        {/* <!--input-for-controlling-the-functionality--> */}
        <input
          type='checkbox'
          className='mobile-nav-input mobile-nav-input-v2'
          id='mobile-nav-check'
          hidden
        />
        <div className='header-top-wrapper'>
          {/* <!--setting-contaienr--> */}
          <div className='container'>
            {/* <!--navigation--> */}
            <nav className='navigation-v2'>
              {/* <!--logo--> */}
              <a href='./index-1.html' className='nav-v2-logo-wrapper'>
                {/* <!--img--> */}
                <img src={logo} alt='Logo' className='nav-v2-logo' />
              </a>
              {/* <!--list--> */}
              <ul className='nav-v2-list'>
                {/* <!--item--> */}
                <li className='nav-v2-item nav-v2-with-drop-down'>
                  لیست گیفت کارت
                  {/* <!--icon--> */}
                  <i class='fa-solid fa-chevron-down nav-v2-icon'></i>
                  {/* <!--drop-list--> */}
                  <ul className='nav-v2-drop-list'>
                    <li className='nav-v2-drop-item'>10$</li>
                    <li className='nav-v2-drop-item'>25$</li>
                    <li className='nav-v2-drop-item'>50$</li>
                    <li className='nav-v2-drop-item'>100$</li>
                  </ul>
                </li>
                {/* <!--item--> */}
                <li className='nav-v2-item'>
                  <a href='./teach-page.html' class='nav-v2-item-link'>
                    آموزش استفاده گیفت کارت
                  </a>
                </li>
                {/* <!--item--> */}
                <li className='nav-v2-item'>
                  <a href='./blog.html' class='nav-v2-item-link'>
                    بلاگ
                  </a>
                </li>
                {/* <!--item--> */}
                <li className='nav-v2-item'>
                  <a href='./error-404.html' class='nav-v2-item-link'>
                    تماس با ما
                  </a>
                </li>
              </ul>
              {/* <!--==mobile-nav==--> */}
              {/* <!--open-icon--> */}
              <label
                for='mobile-nav-check'
                className='mobile-nav-open-label mobile-nav-btns'
              >
                <IoMdMenu className='fa-solid fa-bars open-nav-icon' />
              </label>

              {/* <!--buttons--> */}
              <div className='nav-v2-links-wrapper'>
                {/* <!--link(Shop-link)--> */}
                <a
                  href='./shop-basket-factor.html'
                  className='nav-v2-link nav-v2-shop-link'
                >
                  {/* <!--icon--> */}
                  <i className='fa-solid fa-basket-shopping'></i>
                  <RiShoppingBasketFill className='fa-solid fa-basket-shopping' />
                </a>
                {/* <!--link(login-link)--> */}
                <a
                  href='./login.html'
                  className='nav-v2-link nav-v2-Login-link'
                >
                  {/* <!--text-or-icon--> */}
                  ورود به حساب
                </a>
                {/* <!--login--> */}
                <a
                  href='./login.html'
                  className='nav-login-btn nav-v2-responsive-login'
                >
                  <span className='login-text'>ورود</span>

                  <CiLogin className='fa-solid fa-arrow-right-to-bracket nav-login-icon' />
                </a>
              </div>
            </nav>
          </div>
        </div>
        {/* <!--nav-list--> */}
        <ul className='mobile-nav-list'>
          {/* <!--close-icon--> */}
          <label
            for='mobile-nav-check'
            className='mobile-nav-close-label mobile-nav-btns'
          >
            <FaRegWindowClose className='fa-solid fa-x open-close-icon' />
          </label>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./index-2.html' className='mobile-nav-link'>
              خانه
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./product-page.html' className='mobile-nav-link'>
              گیفت کارت
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./teach-page.html' className='mobile-nav-link'>
              آموزش استفاده گیفت کارت
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./error-404.html' className='mobile-nav-link'>
              راهنمای خرید گیفت کارت
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./blog.html' className='mobile-nav-link'>
              بلاگ
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./error-404.html' className='mobile-nav-link'>
              تماس با ما
            </a>
          </li>
        </ul>
      </div>

      {/* <!--=========Header=========--> */}
      <header className='header body-color'>
        <div className='blog-banner'>
          <div className='container'>
            <div className='blog-content-wrapper margin-top-big'>
              <div className='blog-hero'>
                <img src={blogHeroimg} alt='hero' className='blog-hero-img' />
                <img src={svglogo} alt='logo' className='blog-logo' />
              </div>
              <div className='blog-text-box-wrapper'>
                <div className='blog-text-box-top'>
                  <p className='effective-text'>دسته بندی مفاله: آموزش</p>
                  <p className='addres-text'>
                    خانه/بلاگ/راهنمای جامع نحوه فعال سازی جم در بازی کلش اف کلنز
                  </p>
                </div>
                <div className='blog-text-box-middle'>
                  <h4 className='h4-title blog-title'>
                    راهنمای جامع نحوه فعال سازی جم در بازی کلش اف کلنز
                  </h4>
                  <p className='paragraph'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد.
                  </p>
                </div>
                <div className='blog-info-box'>
                  <span className='info-left-border'></span>
                  <p className='blog-info-text'>
                    <i className='fa-solid fa-pen-to-square blog-info-icon'></i>
                    نویسنده : محمدرضا عبادی
                  </p>
                  <p className='blog-info-text'>
                    <i className='fa-solid fa-calendar-days blog-info-icon'></i>
                    تاریخ انتشار : ۲۲ شهریور ۱۴۰۲
                  </p>
                  <p className='blog-info-text'>
                    <i className='fa-regular fa-rectangle-list blog-info-icon'></i>
                    مدت زمان خواندن : ۱۰ دقیقه
                  </p>
                </div>
              </div>
            </div>
            <div className='blog-info-box blog-info-box-responsive'>
              <span className='info-left-border'></span>
              <p className='blog-info-text'>
                <i className='fa-solid fa-pen-to-square blog-info-icon'></i>
                نویسنده : محمدرضا عبادی
              </p>
              <p className='blog-info-text'>
                <i className='fa-solid fa-calendar-days blog-info-icon'></i>
                تاریخ انتشار : ۲۲ شهریور ۱۴۰۲
              </p>
              <p className='blog-info-text'>
                <i className='fa-regular fa-rectangle-list blog-info-icon'></i>
                مدت زمان خواندن : ۱۰ دقیقه
              </p>
            </div>
            <p className='paragraph blog-down-paraghraph'>
              لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
              استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در
              ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز
              و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.
            </p>
          </div>
        </div>
      </header>

      <main className='main'>
        <section className='blog-content-section'>
          {/* <!--setting-container--> */}
          <div className='container'>
            {/* <!--main-wrapper--> */}
            <div className='blog-content-main-warpper'>
              {/* <!--blog-main-right-wrapper--> */}
              <div className='blog-main-right-wrapper'>
                {/* <!--buy-gift-card-link--> */}
                <a href='./error-404.html' className='buy-gift-card-link'>
                  {/* <!--img--> */}
                  <img
                    src={buygiftcart}
                    alt='img'
                    className='buy-gift-card-img'
                  />
                </a>
                {/* <!--rading-improve-box--> */}
                <div className='reading-improve-box'>
                  {/* <!--text---> */}
                  <p className='reading-improve-text'>
                    میزان پیشرفت خواندن شما
                    {/* <!--sub-text--> */}
                    <span className='reading-improve-sub-text'>
                      مدت زمان خواندن : ۵ دقیقه
                    </span>
                  </p>

                  {/* <!--progress-circle--> */}
                  <div className='progress-circle'>
                    <span className='progress-value'>10%</span>
                  </div>
                </div>
                {/* <!--list-of-content--> */}
                <div className='right-side-list-of-content'>
                  {/* <!--top-wrapper--> */}
                  <div className='r-list-of-content-title-wrapper'>
                    {/* <!--title--> */}
                    <h6 className='h6-title'>
                      {/* <!--icon--> */}
                      <i className='fa-solid fa-layer-group h6-title-icon'></i>
                      فهرست محتوا
                    </h6>
                    {/* <!--number-of-item--> */}
                    <p className='list-of-content-number-item'>۴ مورد</p>
                  </div>
                  {/* <!--content-wrapper--> */}
                  <ul className='r-list-of-content-list'>
                    <li className='r-list-of-content-item'>
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                    </li>
                    <li className='r-list-of-content-item'>
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                    </li>
                    <li className='r-list-of-content-item'>
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                    </li>
                    <li className='r-list-of-content-item'>
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                    </li>
                  </ul>
                </div>
                {/* <!--article-box--> */}
                <a href='./error-404.html' className='r-article-box-link'>
                  <div className='r-article-box'>
                    {/* <!--img--> */}
                    <img
                      src={articleboximg01}
                      alt='img'
                      className='article-box-img'
                    />
                    {/* <!--text--> */}
                    <p className='article-box-title'>
                      {/* <!--sub-title--> */}
                      <span className='article-box-sub-title'>
                        {/* <!--icon--> */}
                        <i className='fa-solid fa-calendar-days article-box-icon'></i>
                        {/* <!--text--> */}
                        مقالات اخیر
                      </span>
                      {/* <!--title--> */}
                      بهترین تنظیمات بازی تیکن 8
                    </p>
                    {/* <!--article-view-all--> */}
                    <p className='article-view-all-text'>
                      مشاهده
                      {/* <!--icon--> */}
                      <i className='fa-solid fa-square-caret-left article-view-all-text-icon'></i>
                    </p>
                  </div>
                </a>
                {/* <!--gift-card-slider-wrapper--> */}
                <div className='gift-card-slider-wrapper'>
                  <div className='gift-card-slider'>
                    <a href='./error-404.html' className='gift-card-link'>
                      <div className='blog-gift-card'>
                        <img
                          src={giftbackimg}
                          alt=''
                          className='blog-gift-card-background-img'
                        />
                        <div className='blog-gift-card-content-box'>
                          <img
                            src={cardcontentimg}
                            alt='img'
                            className='blog-gift-card-img'
                          />
                          <h4 className='card-text'>
                            <span className='card-title'>
                              گیفت کارت پلی استیشن
                            </span>
                            <span className='card-title-eng'>
                              PlayStation Network Gift Card
                            </span>
                          </h4>
                        </div>
                        <div className='card-bottom-text-box'>
                          <p className='blog-gift-card-text'>
                            <i className='fa-solid fa-clock blog-gift-card-icon'></i>
                            تحویل تایم دار
                          </p>
                          <div className='card-add-icon'>
                            <i className='fa-solid fa-plus card-add-icon-content'></i>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a href='./error-404.html' className='gift-card-link'>
                      <div className='blog-gift-card blog-active-gift-card'>
                        <img
                          src={giftbackimg}
                          alt=''
                          className='blog-gift-card-background-img'
                        />
                        <div className='blog-gift-card-content-box'>
                          <img
                            src={cardcontentimg}
                            alt='img'
                            className='blog-gift-card-img'
                          />
                          <h4 className='card-text'>
                            <span className='card-title'>
                              گیفت کارت پلی استیشن
                            </span>
                            <span className='card-title-eng'>
                              PlayStation Network Gift Card
                            </span>
                          </h4>
                        </div>
                        <div className='card-bottom-text-box'>
                          <p className='blog-gift-card-text'>
                            <i className='fa-solid fa-clock blog-gift-card-icon'></i>
                            تحویل تایم دار
                          </p>
                          <div className='card-add-icon'>
                            <i className='fa-solid fa-plus card-add-icon-content'></i>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className='slider-buttons-wrap'>
                    <button className='slider-button'>
                      <span className='slider-button-span'></span>
                    </button>
                    <button className='slider-button active-slider-button'>
                      <span className='slider-button-span'></span>
                    </button>
                    <button className='slider-button'>
                      <span className='slider-button-span'></span>
                    </button>
                  </div>
                </div>
                {/* <!--score-box--> */}
                <div className='score-box'>
                  <h6 className='h6-title'>امتیاز شما به این مقاله</h6>
                  <div className='stars-wrapper'>
                    <i className='fa-solid fa-star stars-icon'></i>
                    <i className='fa-solid fa-star stars-icon'></i>
                    <i className='fa-solid fa-star stars-icon'></i>
                    <i className='fa-solid fa-star stars-icon'></i>
                    <i className='fa-solid fa-star stars-icon'></i>
                  </div>
                  <h6 className='h6-title'>
                    نظر شما در در مورد این مقاله چیست؟
                  </h6>
                  <div className='score-buttons-wrapper'>
                    <input
                      type='radio'
                      name='score-input-radio'
                      className='score-input'
                      id='perfect'
                      hidden
                      defaultChecked
                    />
                    <label htmlFor='perfect' className='score-button'>
                      بسیار عالی
                    </label>
                    <input
                      type='radio'
                      name='score-input-radio'
                      className='score-input'
                      id='well'
                      hidden
                    />
                    <label htmlFor='well' className='score-button'>
                      معمولی
                    </label>
                    <input
                      type='radio'
                      name='score-input-radio'
                      className='score-input'
                      id='bad'
                      hidden
                    />
                    <label htmlFor='bad' className='score-button'>
                      خوب نبود
                    </label>
                  </div>
                </div>
                {/* <!--wirter-share-box--> */}
                <div className='wirter-share-box'>
                  <div className='writer-profie-box'>
                    <div className='profile-img-wrapper'>
                      <img
                        src={writerProfileImg}
                        alt='img'
                        className='writer-profile-img'
                      />
                    </div>
                    <p className='wirter-profile-name'>محمدرضا عبادی</p>
                  </div>
                  <p className='wirter-profile-text'>
                    علاقه مند به حوزه تکنولوژی و انتقال اطلاعات که در این ده سال
                    در این حوزه جمع اوری کرده ام
                  </p>
                  <div className='writer-profile-share-article-wrapper'>
                    <div className='share-article-top-wrapper'>
                      <h6 className='h6-title'>اشتراک گذاری مقاله</h6>
                      <i className='fa-solid fa-share-nodes share-article-icon'></i>
                    </div>
                    <div className='share-article-bottom-wrapper'>
                      <button className='share-option'>
                        <i className='fa-solid fa-clone share-option-icon'></i>
                        کپی مقاله
                      </button>
                      <button className='share-option'>
                        <i className='fa-brands fa-whatsapp share-option-icon'></i>
                      </button>
                      <button className='share-option'>
                        <i className='fa-brands fa-instagram share-option-icon'></i>
                      </button>
                      <button className='share-option'>
                        <i className='fa-brands fa-telegram share-option-icon'></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--blog-main-left-wrapper--> */}
              <div className='blog-main-left-wrapper'>
                {/* <!--====list-of-content-section===--> */}
                <section className='list-of-content-wrapper'>
                  <input
                    type='checkbox'
                    className='list-of-content'
                    id='list-of-content-input'
                    hidden
                  />
                  <label
                    htmlFor='list-of-content-input'
                    className='list-of-content-label'
                  >
                    <div className='list-content-label-top-wrapper'>
                      <p className='list-label-top-text'>
                        <i className='fa-solid fa-align-right list-label-top-icon'></i>
                        فهرست محتوا
                      </p>
                      <div className='list-content-icon'>
                        <i className='fa-solid fa-plus list-plus-icon'></i>
                        <span className='list-mines-icon'></span>
                      </div>
                    </div>
                    <div className='list-content-label-bottom-wrapper'>
                      <input
                        type='checkbox'
                        id='content-input-1'
                        className='bottom-content-input'
                      />
                      <label
                        htmlFor='content-input-1'
                        className='list-content-bottom-label'
                      >
                        <div className='click-box'></div>
                        <p className='label-text'>
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                        </p>
                      </label>
                      <input
                        type='checkbox'
                        id='content-input-2'
                        className='bottom-content-input'
                      />
                      <label
                        htmlFor='content-input-2'
                        className='list-content-bottom-label'
                      >
                        <div className='click-box'></div>
                        <p className='label-text'>
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                        </p>
                      </label>
                      <input
                        type='checkbox'
                        id='content-input-3'
                        className='bottom-content-input'
                      />
                      <label
                        htmlFor='content-input-3'
                        className='list-content-bottom-label'
                      >
                        <div className='click-box'></div>
                        <p className='label-text'>
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                        </p>
                      </label>
                      <input
                        type='checkbox'
                        id='content-input-4'
                        className='bottom-content-input'
                      />
                      <label
                        htmlFor='content-input-4'
                        className='list-content-bottom-label'
                      >
                        <div className='click-box'></div>
                        <p className='label-text'>
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                        </p>
                      </label>
                      <span className='blog-content-line'></span>
                    </div>
                  </label>
                </section>
                {/* <!---=======video-section=====--> */}
                <section className='blog-video-section'>
                  <h4 className='h4-title'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                  </h4>
                  <p className='paragraph'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی.
                  </p>
                  <div className='video-wrapper'>
                    <input
                      type='checkbox'
                      className='video-input'
                      id='video-click'
                    />
                    <label htmlFor='video-click' className='video-banner'>
                      <img
                        src={videoBannerImg}
                        alt='img'
                        className='video-banner-img'
                      />
                      <img
                        src={playBackgroundIcon}
                        className='video-banner-play-img'
                        alt='img'
                      />
                      <div className='video-banner-text-box'>
                        <p className='video-banner-text'>
                          آموزش شارژ حساب کلش اف کلنز
                        </p>
                        <p className='video-banner-text'>
                          مدت زمان ویدیو : 38 ثانیه
                        </p>
                      </div>
                    </label>
                    <video
                      src={videoSrc}
                      className='video-wrapper-video'
                      controls
                    ></video>
                  </div>
                </section>
                {/* <!--info-section--> */}
                <section className='blog-info-section'>
                  <h4 className='h4-title'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                  </h4>
                  <p className='paragraph'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی. لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                    صنعت چاپ و با استفاده از طراحان گرافیک است
                  </p>
                  <div className='blog-info-banner'>
                    <img
                      src={blogInfoImg}
                      alt='img'
                      className='blog-info-img'
                    />
                  </div>
                </section>
                {/* <!--=====blog-podcast-section======--> */}
                <section className='blog-podcast-section'>
                  <h4 className='h4-title'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                  </h4>
                  <p className='paragraph'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی.
                  </p>
                  <div className='podcast-box'>
                    <div className='podcast-text-box'>
                      <h5 className='podcast-title'>
                        <i className='fa-solid fa-music podcast-icon'></i>
                        پادکست آموزشی:
                      </h5>
                      <div className='podcast-text'>
                        آموزش فعال سازی جم در کلش اف کلنر
                      </div>
                    </div>
                    <div className='podcast-track-wrapper'>
                      <input
                        type='checkbox'
                        id='podcast-checkbox'
                        className='podcast-input'
                        hidden
                      />
                      <label
                        htmlFor='podcast-checkbox'
                        className='podcast-box-label podcast-play-btn'
                      >
                        <i className='fa-solid fa-play podcast-play-icon podcast-btn-icon'></i>
                        <i className='fa-solid fa-pause podcast-pause-icon podcast-btn-icon'></i>
                      </label>
                      <div className='music-loader-box'>
                        <span className='stroke'></span>
                        <span className='stroke'></span>
                        <span className='stroke'></span>
                        <span className='stroke'></span>
                        <span className='stroke'></span>
                        <span className='stroke'></span>
                        <span className='stroke'></span>
                        <span className='stroke'></span>
                        <span className='stroke'></span>
                        <span className='stroke'></span>
                        <span className='stroke'></span>
                        <span className='stroke'></span>
                        <span className='stroke'></span>
                        <span className='stroke'></span>
                        <span className='stroke'></span>
                      </div>
                    </div>
                  </div>
                  <h4 className='h4-title'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                  </h4>
                  <ul className='podcast-list'>
                    <li className='podcast-item'>
                      <img
                        src={tickSquare}
                        alt='img'
                        className='podcast-item-icon'
                      />
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                    </li>
                    <li className='podcast-item'>
                      <img
                        src={tickSquare}
                        alt='img'
                        className='podcast-item-icon'
                      />
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                    </li>
                    <li className='podcast-item'>
                      <img
                        src={tickSquare}
                        alt='img'
                        className='podcast-item-icon'
                      />
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                    </li>
                  </ul>
                  <div className='read-more-box'>
                    <p className='read-more-text'>
                      <i className='fa-solid fa-lightbulb read-more-box-icon'></i>
                      همچنین بخوانید :
                      <span className='read-more-box-text-span'>
                        بهترین بازی های که میتوانید با پلاس پلی اسیتشن تجربه
                        کنید
                      </span>
                    </p>
                  </div>
                </section>
                {/* <!--=====table-section===========--> */}
                <section className='table-section'>
                  <h4 className='h4-title'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                  </h4>
                  <p className='paragraph'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی. لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                    صنعت چاپ و با استفاده از طراحان گرافیک است
                  </p>
                  <div className='table-wrapper'>
                    <div className='table-header-wrapper'>
                      <p className='table-text table-text-white'>
                        سرعت گوشی در اجرای کلش اف کلنز
                      </p>
                      <p className='table-text table-text-white'>جی ال ایکس </p>
                      <p className='table-text table-text-white'>آیفون</p>
                    </div>
                    <div className='table-body-wrapper'>
                      <p className='table-text table-text-black'>
                        قدرت اجرای بازی قوی{' '}
                      </p>
                      <p className='table-text table-text-black'>ندارد</p>
                      <p className='table-text table-text-black'>دارد</p>
                    </div>
                    <div className='table-body-wrapper'>
                      <p className='table-text table-text-black'>
                        قدرت اجرای بازی قوی{' '}
                      </p>
                      <p className='table-text table-text-black'>ندارد</p>
                      <p className='table-text table-text-black'>دارد</p>
                    </div>
                    <div className='table-body-wrapper'>
                      <p className='table-text table-text-black'>
                        قدرت اجرای بازی قوی{' '}
                      </p>
                      <p className='table-text table-text-black'>ندارد</p>
                      <p className='table-text table-text-black'>دارد</p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        {/* <!--=======last-articles-section==========--> */}
        <section className='other-article-section'>
          {/* <!--setting-container--> */}
          <div className='container'>
            {/* <!--main-wrapper--> */}
            <div className='other-article-wrapper'>
              {/* <!--sub-wrapper--> */}
              <div className='other-article-sub-wrapper'>
                <div className='other-article-logo-box'>
                  <img
                    src={logopng}
                    alt='logo'
                    className='other-article-logo'
                  />
                  <p className='other-article-main-text'>
                    <span className='other-article-main-span'>
                      آخرین مقالات
                    </span>
                    <span className='other-article-sub-span'>
                      گنجینه ای از اخبار و آموزش
                    </span>
                  </p>
                </div>
                <div className='slider-x'>
                  <div className='slide'>
                    <a href='./error-404.html' className='other-article-link'>
                      <div className='other-article-box'>
                        <div className='other-article-img-wrapper'>
                          <img
                            src={otherArticleImg3}
                            alt='img'
                            className='other-article-img'
                          />
                        </div>
                        <h5 className='other-article-text'>
                          بهترین مپ برای بازی پابجی
                        </h5>
                      </div>
                    </a>
                    <a href='./error-404.html' className='other-article-link'>
                      <div className='other-article-box'>
                        <div className='other-article-img-wrapper'>
                          <img
                            src={otherArticleImg2}
                            alt='img'
                            className='other-article-img'
                          />
                        </div>
                        <h5 className='other-article-text'>
                          بهترین مپ برای بازی پابجی
                        </h5>
                      </div>
                    </a>
                    <a href='./error-404.html' className='other-article-link'>
                      <div className='other-article-box'>
                        <div className='other-article-img-wrapper'>
                          <img
                            src={otherArticleImg1}
                            alt='img'
                            className='other-article-img'
                          />
                        </div>
                        <h5 className='other-article-text'>
                          بهترین مپ برای بازی پابجی
                        </h5>
                      </div>
                    </a>
                  </div>
                  <div className='slide'>
                    <a href='./error-404.html' className='other-article-link'>
                      <div className='other-article-box'>
                        <div className='other-article-img-wrapper'>
                          <img
                            src={otherArticleImg3}
                            alt='img'
                            className='other-article-img'
                          />
                        </div>
                        <h5 className='other-article-text'>
                          بهترین مپ برای بازی پابجی
                        </h5>
                      </div>
                    </a>
                    <a href='./error-404.html' className='other-article-link'>
                      <div className='other-article-box'>
                        <div className='other-article-img-wrapper'>
                          <img
                            src={otherArticleImg2}
                            alt='img'
                            className='other-article-img'
                          />
                        </div>
                        <h5 className='other-article-text'>
                          بهترین مپ برای بازی پابجی
                        </h5>
                      </div>
                    </a>
                    <a href='./error-404.html' className='other-article-link'>
                      <div className='other-article-box'>
                        <div className='other-article-img-wrapper'>
                          <img
                            src={otherArticleImg1}
                            alt='img'
                            className='other-article-img'
                          />
                        </div>
                        <h5 className='other-article-text'>
                          بهترین مپ برای بازی پابجی
                        </h5>
                      </div>
                    </a>
                  </div>
                  <div className='slide'>
                    <a href='./error-404.html' className='other-article-link'>
                      <div className='other-article-box'>
                        <div className='other-article-img-wrapper'>
                          <img
                            src={otherArticleImg3}
                            alt='img'
                            className='other-article-img'
                          />
                        </div>
                        <h5 className='other-article-text'>
                          بهترین مپ برای بازی پابجی
                        </h5>
                      </div>
                    </a>
                    <a href='./error-404.html' className='other-article-link'>
                      <div className='other-article-box'>
                        <div className='other-article-img-wrapper'>
                          <img
                            src={otherArticleImg2}
                            alt='img'
                            className='other-article-img'
                          />
                        </div>
                        <h5 className='other-article-text'>
                          بهترین مپ برای بازی پابجی
                        </h5>
                      </div>
                    </a>
                    <a href='./error-404.html' className='other-article-link'>
                      <div className='other-article-box'>
                        <div className='other-article-img-wrapper'>
                          <img
                            src={otherArticleImg1}
                            alt='img'
                            className='other-article-img'
                          />
                        </div>
                        <h5 className='other-article-text'>
                          بهترین مپ برای بازی پابجی
                        </h5>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              {/* <!--slider-buttons-wrapper--> */}
              <div className='slider-buttons-wrap'>
                <button className='slider-button'>
                  <span className='slider-button-span'></span>
                </button>
                <button className='slider-button active-slider-button'>
                  <span className='slider-button-span'></span>
                </button>
                <button className='slider-button'>
                  <span className='slider-button-span'></span>
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* <!--=======comment-section==========--> */}
        <section className='comment-section'>
          {/* <!--setting-container--> */}
          <div className='smaller-container'>
            {/* <!--main-wrapper--> */}
            <div className='comment-section-wrapper'>
              {/* <!--title--> */}
              <h5 className='comment-section-title'>
                {/* <!--icon--> */}
                <i className='fa-solid fa-comments comment-section-title-icon'></i>
                دیدگاه کاربران
              </h5>
              {/* <!--comment-box--> */}
              <div className='comment-box'>
                <div className='comment-box-content-wrapper'>
                  {/* <!--comment-profile--> */}
                  <div className='comment-profile-img'>
                    {/* <!--img--> */}
                    <img
                      src='./assets/images/comment-profile-img.png'
                      alt='img'
                      className='comment-profile-img-content'
                    />
                  </div>
                  {/* <!--comment-content--> */}
                  <div className='comment-content'>
                    {/* <!--comment-content-top--> */}
                    <div className='comment-content-top'>
                      {/* <!--comment-owner-name--> */}
                      <p className='comment-owner-name'>
                        اصغر جعفری | کاربر مهمان
                      </p>
                      {/* <!--top-left-wrapper--> */}
                      <div className='comment-top-left-wrapper'>
                        {/* <!--button--> */}
                        <button className='comment-opinion-button'>
                          {/* <!--icon--> */}
                          <i className='fa-regular fa-thumbs-up comment-opinion-icon like-icon'></i>
                          پسندیدم (۵)
                        </button>
                        {/* <!--button--> */}
                        <button className='comment-opinion-button'>
                          {/* <!--icon--> */}
                          <i className='fa-regular fa-thumbs-up comment-opinion-icon deslike-icon'></i>
                          پسندیدم (۵)
                        </button>
                      </div>
                    </div>
                    {/* <!--text--> */}
                    <p className='comment-text'>
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ
                      و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه
                      روزنامه و مجله در ستون و سطرآنچنان که لازم است
                    </p>
                    {/* <!--comment-response-box--> */}
                    <div className='comment-response-box'>
                      {/* <!--img--> */}
                      {/* <!--comment-profile--> */}
                      <div className='comment-profile-img-response'>
                        {/* <!--img--> */}
                        <img
                          src='./assets/images/comment-response-profile.png'
                          alt='img'
                          className='comment-profile-img-content'
                        />
                      </div>
                      {/* <!--comment-content--> */}
                      <div className='comment-content comment-content-response'>
                        {/* <!--comment-content-top--> */}
                        <div className='comment-content-top comment-content-top-responsive'>
                          {/* <!--comment-owner-name--> */}
                          <p className='comment-owner-name comment-owner-response'>
                            اصغر جعفری | کاربر مهمان
                          </p>
                          {/* <!--top-left-wrapper--> */}
                          <div className='comment-top-left-wrapper'>
                            {/* <!--button--> */}
                            <button className='comment-opinion-button comment-opinion-button-response'>
                              {/* <!--icon--> */}
                              <i className='fa-regular fa-thumbs-up comment-opinion-icon like-icon'></i>
                              پسندیدم (۵)
                            </button>
                            {/* <!--button--> */}
                            <button className='comment-opinion-button comment-opinion-button-response'>
                              {/* <!--icon--> */}
                              <i className='fa-regular fa-thumbs-up comment-opinion-icon deslike-icon'></i>
                              پسندیدم (۵)
                            </button>
                          </div>
                        </div>
                        {/* <!--text--> */}
                        <p className='comment-text comment-text-response'>
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                          چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون
                          بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--send-comment---> */}
              <div class='send-comment-wrapper'>
                {/* <!--top-wrapper--> */}
                <div class='send-comment-top'>
                  {/* <!--title--> */}
                  <h5 class='comment-section-title  margin-bottom-0'>
                    {/* <!--icon--> */}
                    <i class='fa-solid fa-comments comment-section-title-icon'></i>
                    دیدگاه کاربران
                  </h5>
                  {/* <!--text--> */}
                  <p class='send-comment-text'>
                    {/* <!--icon--> */}
                    <i class='fa-solid fa-shield send-comment-icon'></i>
                    پس از تایید ادمین نظر شما ارسال می شود
                  </p>
                </div>
                <form action='./error-404.html' className='comment-send-form'>
                  <textarea
                    name='comment-send-text-area'
                    placeholder='نظر خود را وارد کنید'
                    className='comment-send-text-area'
                  ></textarea>
                  <div className='form-inputs-wrapper'>
                    <input
                      type='text'
                      name='name'
                      placeholder='نام و نام خانوادگی'
                      className='form-input form-name-input'
                      required
                    />
                    <input
                      type='email'
                      name='email'
                      placeholder='ایمیل خود را وارد کنید'
                      className='form-input form-email-input'
                      required
                    />
                    <div className='stars-wrapper'>
                      <i className='fa-solid fa-star stars-icon'></i>
                      <i className='fa-solid fa-star stars-icon'></i>
                      <i className='fa-solid fa-star stars-icon'></i>
                      <i className='fa-solid fa-star stars-icon'></i>
                      <i className='fa-solid fa-star stars-icon'></i>
                    </div>
                    <button type='submit' className='form-send-button'>
                      ارسال دیدگاه
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!--=========Main=========--> */}

      <ToastContainer toastClassName='toast-right' />
    </div>
  )
}

export default Blog
