import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

// icon
import {
  FaCircleXmark,
  FaPenToSquare,
  FaTriangleExclamation,
} from 'react-icons/fa6'
import { IoMdExit } from 'react-icons/io'
import { IoMenu } from 'react-icons/io5'
import {
  FaShoppingBasket,
  FaChartBar,
  FaQrcode,
  FaStore,
  FaCommentDots,
  FaRegCommentDots,
  FaCloudDownloadAlt,
} from 'react-icons/fa'

import logo from '../assets/images/logo.png'
import finger from '../assets/images/finger-pattern.png'

function Authentication() {
  const navigate = useNavigate()

  // popup number

  const [isCodeSent, setIsCodeSent] = useState(false)
  const [timer, setTimer] = useState(120)
  const [verificationCode, setVerificationCode] = useState('')
  const [inputValue, setInputValue] = useState('') // برای ذخیره ایمیل یا شماره موبایل
  const [verificationType, setVerificationType] = useState('') // 'email' یا 'phone'

  // شروع شمارش معکوس پس از ارسال کد
  useEffect(() => {
    let interval = null
    if (isCodeSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    } else if (timer === 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [isCodeSent, timer])

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  const handleSendCode = (e, type) => {
    e.preventDefault()
    if (inputValue) {
      setIsCodeSent(true)
      setTimer(120)
      setVerificationType(type) // تنظیم نوع تایید برای ارسال کد تایید
      console.log(
        `Sending verification code to ${type === 'email' ? 'email' : 'phone'}:`,
        inputValue
      )
      // ارسال درخواست API بر اساس نوع تایید (ایمیل یا موبایل)
    }
  }

  const handleCancel = () => {
    setIsCodeSent(false)
    setTimer(120)
    setVerificationCode('')
    setInputValue('')
  }
  // detail

  const [profileData, setProfileData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: 'youremail@email.com',
    meli_number: '',
    telegram: '',
    instagram: '',
  })

  const [responseMessage, setResponseMessage] = useState(null)
  const [loading, setLoading] = useState(false) // مدیریت وضعیت loading

  // تابع برای مدیریت تغییرات فیلدهای ورودی
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  // submit handle
  const handleSubmit = async (e) => {
    e.preventDefault() // جلوگیری از رفرش صفحه

    const accessToken = sessionStorage.getItem('access_token')

    setLoading(true) // شروع loading

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://steelblue-ferret-758809.hostingersite.com/api/profile',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`, // توکن دسترسی را جایگزین کنید
      },
      data: JSON.stringify(profileData),
    }

    try {
      const response = await axios.request(config)
      setResponseMessage(`Success: ${JSON.stringify(response.data)}`)
    } catch (error) {
      console.error('Error updating profile:', error)
      setResponseMessage(
        `Error: ${error.response ? error.response.data : error.message}`
      )
    } finally {
      setLoading(false) // توقف loading پس از دریافت پاسخ
    }
  }

  // get profile
  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://steelblue-ferret-758809.hostingersite.com/api/profile',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    }

    axios
      .request(config)
      .then((response) => {
        const data = response.data.data
        setProfileData({
          first_name: data.first_name,
          last_name: data.last_name,
          phone: data.phone,
          email: data.email,
          meli_number: data.meli_number,
          instagram: data.instagram,
          telegram: data.telegram,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <div className='body-white-color-2'>
      <div className='page-main-contaier body-white-color-2'>
        {/* <!--=========Main============--> */}
        <main className='main'>
          {/* <!--============Dashboard-section--> */}
          <section className='dashboard-sectoin'>
            {/* <!--setting-container--> */}
            <div className='container'>
              {/* <!--dashboard-content---> */}
              <div className='dashboard-content-wrapper'>
                {/* <!--==========input-for-controlling-the-funtionality========--> */}
                <input
                  type='checkbox'
                  className='side-nav-input-v1'
                  id='side-nav-input-v1'
                  hidden
                />
                {/* <!---side-nav--> */}
                <div className='dashboard-side-nav mini-section-box-style'>
                  {/* <!--icon--> */}
                  <label
                    for='side-nav-input-v1'
                    className='side-nav-close-label'
                  >
                    {/* <!--icon--> */}
                    <FaCircleXmark className='fa-solid fa-xmark' />
                  </label>
                  {/* <!--logo--> */}
                  <div className='side-nav-logo-wrapper'>
                    {/* <!--img--> */}
                    <img src={logo} alt='img' className='side-nav-logo' />
                    {/* <!--text--> */}
                    <h4 className='side-nav-logo-text'>زود گیفت</h4>
                  </div>
                  {/* <!--list--> */}
                  <ul className='side-nav-list'>
                    {/* <!--link-item--> */}
                    <a href='/dashboard' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <IoMenu className='fa-solid fa-table-list side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>داشبورد</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='/dashboard/orders' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaShoppingBasket className='fa-solid fa-basket-shopping side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>مشاهده سفارشات</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='#' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item '>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaChartBar className='fa-solid fa-chart-line side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>تراکنش اخیر</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a
                      href='/dashboard/authentication'
                      className='side-nav-link'
                    >
                      {/* <!--item--> */}
                      <li className='side-nav-item active-side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}
                        <FaQrcode className='fa-solid fa-qrcode side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>احراز هویت</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='#' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaStore className='fa-solid fa-store side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>سفارش عمده</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='/dashboard/support' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaCommentDots className='fa-solid fa-comment-dots side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>تیکت پشتیبانی</p>
                      </li>
                    </a>
                  </ul>
                  <div className='side-nav-down-link-wrapper'>
                    {/* <!--link--> */}
                    <a href='/' className='side-nav-link-v2'>
                      بازگشت به فروشگاه
                    </a>
                  </div>
                </div>

                {/*  <!--dashboard-content--> */}
                <div className='dashboard-content'>
                  {/* <!--dashbord-top--> */}
                  <div className='dashboard-top-wrapper mini-section-box-style'>
                    {/* <!--right-side--> */}
                    <div className='dashboard-top-right-wrapper'>
                      {/* <!--link--> */}
                      <a
                        href='#'
                        className='dashboard-top-link dashboard-top-link-mobile'
                      >
                        {/* <!--icon--> */}
                        <IoMdExit className='fa-solid fa-right-from-bracket' />
                        خروج
                      </a>
                      {/* <!--lable--> */}
                      <label
                        for='side-nav-input-v1'
                        className='side-nav-open-label'
                      >
                        پنل کاربری
                      </label>
                    </div>
                    {/* <!--left-side--> */}
                    <div className='dashboard-top-left-wrapper'>
                      {/* <!--text-box--> */}
                      <div className='dashboard-left-text-box'>
                        {/* <!--link--> */}
                        <a href='#' className='dashboard-top-link'>
                          {/* <!--icon--> */}
                          <FaRegCommentDots className='fa-regular fa-comment-dots' />
                          پشتیبانی
                        </a>
                        <p className='dashboard-top-text'>
                          {/* <!--text--> */}
                          <span className='dashboard-top-text-span'>
                            ۰۹۱۶۰۲۶۵۶۶۱
                          </span>
                          {/* <!--line--> */}
                          <span className='dashboard-top-text-line-span'></span>
                          {/* <!--text--> */}
                          <span className='dashboard-top-text-span'>
                            محمدرضا عبادی
                          </span>
                        </p>
                      </div>
                      {/* <!--logo--> */}
                      <div className='dashboard-top-logo-wrapper'>
                        {/* <!--logo--> */}
                        <img
                          src={logo}
                          alt='img'
                          className='dashboard-log-img'
                        />
                      </div>
                    </div>
                  </div>
                  {/* <!--form--> */}
                  <form className='form-v4 mini-section-box-style' action='#'>
                    {/* <!--right-side--> */}
                    <div className='form-v4-right-side'>
                      {/* <!--form-group-wrapper--> */}
                      <div className='form-group-wrapper-v4'>
                        {/* <!--title--> */}
                        <h6 className='form-v4-title'>اطلاعات هویتی :</h6>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4'>
                          {/* <!--label--> */}
                          <label
                            for='form-v4-input-1'
                            className='form-v4-label'
                          >
                            نام و نام خانوادگی
                          </label>
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='name'
                            id='form-v4-input-1'
                            className='form-v4-input'
                            value={`${profileData.first_name} ${profileData.last_name}`}
                            readOnly
                          />
                          {/* <!--btn--> */}
                          <label
                            for='name-family-name-input'
                            className='from-v4-edite-btn authentication-label'
                          >
                            {/* <!--icon--> */}
                            <FaPenToSquare className='fa-solid fa-pen-to-square form-v4-edite-icon' />
                          </label>
                        </div>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4'>
                          {/* <!--label--> */}
                          <label
                            for='form-v4-input-2'
                            className='form-v4-label'
                          >
                            شماره موبایل
                          </label>
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='phone'
                            id='form-v4-input-2'
                            className='form-v4-input'
                            value={profileData.phone}
                            readOnly
                          />
                          {/* <!--btn--> */}
                          <label
                            for='pop-up-number-input'
                            className='from-v4-edite-btn'
                          >
                            {/* <!--icon--> */}

                            <FaPenToSquare className='fa-solid fa-pen-to-square form-v4-edite-icon' />
                          </label>
                        </div>
                      </div>
                      {/* <!--form-group-wrapper--> */}
                      <div className='form-group-wrapper-v4'>
                        {/* <!--title--> */}
                        <h6 className='form-v4-title'>تغییر رمز عبور:</h6>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4 form-group-v4-2'>
                          {/* <!--input--> */}
                          <input
                            type='password'
                            name='password'
                            id='form-v4-input-1'
                            className='form-v4-input-2'
                            placeholder='رمز عبور جدید'
                          />
                        </div>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4 form-group-v4-2'>
                          {/* <!--input--> */}
                          <input
                            type='password'
                            name='password'
                            id='form-v4-input-2'
                            className='form-v4-input-2'
                            placeholder='تکرار رمز عبور جدید'
                          />
                          {/* <!--button--> */}
                          <button className='form-group-v4-btn-2'>
                            ویرایش
                            <img
                              src={finger}
                              alt='img'
                              className='form-group-v4-btn-2-icon'
                            />
                          </button>
                        </div>
                        {/* <!--wraning--> */}
                        <div className='warning-box warning-box-v3 dashboard-wraning-box'>
                          {/* <!--title--> */}
                          <h6 className='warning-box-title'>
                            رمز عبور شما باید حداقل ۸ حرف باشد.
                          </h6>
                          {/* <!--list--> */}
                          <ul className='warning-box-list'>
                            {/* <!--item--> */}
                            <li className='warning-box-item'>شامل عدد</li>
                            {/* <!--item--> */}
                            <li className='warning-box-item'>حداقل ۸ حرف</li>
                            {/* <!--item--> */}
                            <li className='warning-box-item'>
                              شامل علامت (!@#$%&*^)
                            </li>
                            {/* <!--item--> */}
                            <li className='warning-box-item'>
                              شامل یک حرف بزرگ و کوچک
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <!--left-side--> */}
                    <div className='form-v4-left-side'>
                      {/* <!--form-group-wrapper--> */}
                      <div className='form-group-wrapper-v4'>
                        {/* <!--title--> */}
                        <h6 className='form-v4-title'>&nbsp;</h6>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4'>
                          {/* <!--label--> */}
                          <label
                            for='form-v4-input-5'
                            className='form-v4-label'
                          >
                            ایمیل
                          </label>
                          {/* <!--input--> */}
                          <input
                            type='email'
                            name='email'
                            id='form-v4-input-5'
                            className='form-v4-input'
                            value={profileData.email}
                            readOnly
                          />
                          {/* <!--btn--> */}
                          <label
                            for='email-pop-up-input'
                            className='from-v4-edite-btn'
                          >
                            {/* <!--icon--> */}

                            <FaPenToSquare className='fa-solid fa-pen-to-square form-v4-edite-icon' />
                          </label>
                        </div>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4'>
                          {/* <!--label--> */}
                          <label
                            for='form-v4-input-6'
                            className='form-v4-label'
                          >
                            کد ملی
                          </label>
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='meli_number'
                            id='form-v4-input-6'
                            className='form-v4-input'
                            value={profileData.meli_number}
                            readOnly
                          />
                          {/* <!--btn--> */}
                          <label
                            for='pop-up-id-number-input'
                            className='from-v4-edite-btn'
                          >
                            {/* <!--icon--> */}
{/* 
                            <FaPenToSquare className='fa-solid fa-pen-to-square form-v4-edite-icon' /> */}
                          </label>
                        </div>
                      </div>
                      {/* <!--form-group-wrapper--> */}
                      <div className='form-group-wrapper-v4'>
                        {/* <!--title--> */}
                        <h6 className='form-v4-title'>ثبت شبکه اجتماعی :</h6>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4'>
                          {/* <!--label--> */}
                          <label
                            for='form-v4-input-7'
                            className='form-v4-label'
                          >
                            تلگرام
                          </label>
                          {/* <!--input--> */}
                          <input
                            type='email'
                            name='email'
                            id='form-v4-input-7'
                            className='form-v4-input'
                            value={profileData.telegram}
                          />
                          {/* <!--btn--> */}
                          <label
                            for='pop-up-telegram-input'
                            className='from-v4-edite-btn'
                          >
                            {/* <!--icon--> */}

                            <FaPenToSquare className='fa-solid fa-pen-to-square form-v4-edite-icon' />
                          </label>
                        </div>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4'>
                          {/* <!--label--> */}
                          <label
                            for='form-v4-input-8'
                            className='form-v4-label'
                          >
                            اینستاگرام
                          </label>
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='name'
                            id='form-v4-input-8'
                            className='form-v4-input'
                            value={profileData.instagram}
                          />
                          {/* <!--btn--> */}
                          <label
                            for='pop-up-instagram-input'
                            className='from-v4-edite-btn'
                          >
                            {/* <!--icon--> */}

                            <FaPenToSquare className='fa-solid fa-pen-to-square form-v4-edite-icon' />
                          </label>
                        </div>
                        {/* <!--upload-box-- */}
                        <div className='form-v4-upload-box'>
                          {/* <!--input-wrapper--> */}
                          <input
                            type='file'
                            className='form-v4-upload-input'
                            id='form-v4-upload-input'
                            hidden
                          />
                          {/* <!--label--> */}
                          <label
                            for='form-v4-upload-input'
                            className='form-v4-uload-label'
                          >
                            {/* <!--icon--> */}

                            <FaCloudDownloadAlt className='fa-solid fa-cloud-arrow-up' />
                          </label>
                          {/* <!--title--> */}
                          <h6 className='form-v4-title'>
                            آپلود تصویر پروفایل :
                          </h6>
                          {/* <!--form-upload-warning--> */}
                          <div className='form-v4-upload-warning-box'>
                            {/* <!--icon--> */}
                            <FaTriangleExclamation className='fa-solid fa-triangle-exclamation warning-icon-v4' />
                            {/* <!--text--> */}
                            <p className='warning-text-v4'>
                              حجم تصویر ارسالی می بایست کمتر از 1 مگابایت باشد
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  {/* <!--input-for-controlling-the-funtionality--> */}
                  <input
                    type='checkbox'
                    id='name-family-name-input'
                    className='authentication-input'
                    hidden
                  />
                  {/* <!--input-for-controlling-the-funtionality--> */}
                  <input
                    type='checkbox'
                    id='pop-up-number-input'
                    className='authentication-input'
                    hidden
                  />
                  {/* <!--input-for-controlling-the-funtionality--> */}
                  <input
                    type='checkbox'
                    id='email-pop-up-input'
                    className='authentication-input'
                    hidden
                  />
                  {/* <!--input-for-controlling-the-funtionality--> */}
                  <input
                    type='checkbox'
                    id='pop-up-id-number-input'
                    className='authentication-input'
                    hidden
                  />
                  {/* <!--input-for-controlling-the-funtionality--> */}
                  <input
                    type='checkbox'
                    id='pop-up-telegram-input'
                    className='authentication-input'
                    hidden
                  />
                  {/* <!--input-for-controlling-the-funtionality--> */}
                  <input
                    type='checkbox'
                    id='pop-up-instagram-input'
                    className='authentication-input'
                    hidden
                  />
                  {/* <!--pop-up-window-wrapper--> */}
                  <div className='pop-up-window-wrapper'>
                    {/* <!--name-and-family-name--> */}
                    <div className='pop-up-window name-family-pop-up-window'>
                      {/* <!--text--> */}
                      <h4 class='pop-up-title'>ثبت اطلاعات شناسایی</h4>
                      {/* <!--text--> */}
                      <p class='pop-up-text'>
                        لطفا اطلاعات شناسایی خود را وارد کنید. نام و نام
                        خانوادگی شما باید با اطلاعاتی که وارد می‌کنید همخوانی
                        داشته باشند.
                      </p>
                      {/* <!--content--> */}
                      <div className='pop-up-form-wrapper'>
                        <form
                          action='#'
                          onSubmit={handleSubmit}
                          className='pop-up-form-v4'
                        >
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='first_name'
                            placeholder='نام'
                            value={profileData.first_name}
                            onChange={handleInputChange}
                            className='pop-up-form-input'
                          />

                          <input
                            type='text'
                            name='last_name'
                            placeholder='نام خانوادگی'
                            value={profileData.last_name}
                            onChange={handleInputChange}
                            className='pop-up-form-input'
                          />

                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='meli_number'
                            placeholder='کد ملی'
                            value={profileData.meli_number}
                            onChange={handleInputChange}
                            className='pop-up-form-input'
                          />
                          {/* <!--buttons-wrapper--> */}
                          <div className='pop-up-buttons-wrapper'>
                            {/* <!--button--> */}
                            <label
                              for='name-family-name-input'
                              className='pop-up-button pop-up-white-button'
                            >
                              بازگشت و انصراف
                            </label>
                            {/* <!--button--> */}
                            <button
                              type='submit'
                              className='pop-up-button pop-up-blue-button'
                              disabled={loading} // غیرفعال‌سازی دکمه هنگام loading
                            >
                              {loading ? (
                                <span
                                  className='spinner'
                                  role='status'
                                  aria-hidden='true'
                                ></span>
                              ) : (
                                'ویرایش و تغییر اطلاعات'
                              )}
                            </button>
                            {responseMessage && <p>{responseMessage}</p>}
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <!--number--> */}
                    <div className='pop-up-window pop-up-number-window'>
                      {/* <!--text--> */}
                      <h4 className='pop-up-title'>ثبت | تغییر شماره موبایل</h4>
                      {/* <!--text--> */}
                      <p className='pop-up-text'>
                        لطفا شماره تماس با نام و نام خانوادگی و کدملی شما مطابقت
                        داشته باشد .
                      </p>
                      {/* <!--content--> */}
                      <div className='pop-up-form-wrapper'>
                        <form action='#' class='pop-up-form-v4'>
                          {isCodeSent && verificationType === 'phone' ? (
                            <>
                              <input
                                type='text'
                                name='verification-code'
                                className='pop-up-form-input'
                                placeholder='کد تایید : '
                                value={verificationCode}
                                onChange={(e) =>
                                  setVerificationCode(e.target.value)
                                }
                              />
                              <p className='pop-up-text pop-up-text-v2'>
                                زمان باقی‌مانده: {formatTime(timer)}
                              </p>
                              <div className='pop-up-buttons-wrapper'>
                                <button
                                  type='button'
                                  className='pop-up-button pop-up-blue-button'
                                  onClick={() => alert('کد تایید شده!')}
                                >
                                  تایید کد
                                </button>
                                <button
                                  type='button'
                                  className='pop-up-button pop-up-white-button'
                                  onClick={handleCancel}
                                >
                                  انصراف
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <input
                                type='number'
                                name='phone-number'
                                className='pop-up-form-input'
                                placeholder='شماره موبایل : '
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                              />
                              <p className='pop-up-text pop-up-text-v2'>
                                برای ثبت این شماره باید آن را تایید کنید.
                              </p>
                              <div className='pop-up-buttons-wrapper'>
                                <label
                                  htmlFor='pop-up-number-input'
                                  className='pop-up-button pop-up-white-button'
                                  onClick={handleCancel}
                                >
                                  بازگشت و انصراف
                                </label>
                                <button
                                  type='submit'
                                  className='pop-up-button pop-up-blue-button'
                                  onClick={(e) => handleSendCode(e, 'phone')}
                                >
                                  ارسال کد
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                    {/* <!--email--> */}
                    <div className='pop-up-window email-pop-up-window'>
                      {/* <!--text--> */}
                      <h4 className='pop-up-title'>ویرایش ایمیل</h4>
                      {/* <!--text--> */}
                      <p className='pop-up-text'>
                        لطفا ایمیل خود را با دقت وارد کنید
                      </p>
                      {/* <!--content--> */}
                      <div className='pop-up-form-wrapper'>
                        <form action='#' className='pop-up-form-v4'>
                          {isCodeSent && verificationType === 'email' ? (
                            <>
                              <input
                                type='text'
                                name='verification-code'
                                className='pop-up-form-input'
                                placeholder='کد تایید : '
                                value={verificationCode}
                                onChange={(e) =>
                                  setVerificationCode(e.target.value)
                                }
                              />
                              <p className='pop-up-text pop-up-text-v2'>
                                زمان باقی‌مانده: {formatTime(timer)}
                              </p>
                              <div className='pop-up-buttons-wrapper'>
                                <button
                                  type='button'
                                  className='pop-up-button pop-up-blue-button'
                                  onClick={() => alert('کد تایید شده!')}
                                >
                                  تایید کد
                                </button>
                                <button
                                  type='button'
                                  className='pop-up-button pop-up-white-button'
                                  onClick={handleCancel}
                                >
                                  انصراف
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <input
                                type='email'
                                name='email'
                                className='pop-up-form-input'
                                placeholder='ایمیل :'
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                              />
                              <div className='pop-up-buttons-wrapper'>
                                <label
                                  htmlFor='email-pop-up-input'
                                  className='pop-up-button pop-up-white-button'
                                  onClick={handleCancel}
                                >
                                  بازگشت و انصراف
                                </label>
                                <button
                                  type='submit'
                                  className='pop-up-button pop-up-blue-button'
                                  onClick={(e) => handleSendCode(e, 'email')}
                                >
                                  ارسال کد
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                    {/* <!--name-and-family-name--> */}
                    <div className='pop-up-window id-number-pop-up-window'>
                      {/* <!--text--> */}
                      <h4 className='pop-up-title'>ثبت اطلاعات شناسایی</h4>
                      {/* <!--text--> */}
                      <p className='pop-up-text'>
                        لطفا اطلاعات شناسایی خود را وارد کنید. نام و نام
                        خانوادگی شما باید با اطلاعاتی که وارد می‌کنید همخوانی
                        داشته باشند.
                      </p>
                      {/* <!--content--> */}
                      <div className='pop-up-form-wrapper'>
                        <form action='#' className='pop-up-form-v4'>
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='name'
                            className='pop-up-form-input'
                            placeholder='نام و نام خانوادگی:'
                          />
                          {/* <!--input--> */}
                          <input
                            type='number'
                            name='id-number'
                            className='pop-up-form-input'
                            placeholder='کد ملی : '
                          />
                          {/* <!--buttons-wrapper--> */}
                          <div class='pop-up-buttons-wrapper'>
                            {/* <!--button--> */}
                            <label
                              for='pop-up-id-number-input'
                              className='pop-up-button pop-up-white-button'
                            >
                              بازگشت و انصراف
                            </label>
                            {/* <!--button--> */}
                            <button
                              type='submit'
                              className='pop-up-button pop-up-blue-button'
                            >
                              ویرایش و تغییر اطلاعات
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <!--telegram--> */}
                    <div className='pop-up-window telegram-pop-up-window'>
                      {/* <!--text--> */}
                      <h4 className='pop-up-title'>
                        ثبت | ویرایش شبکه اجتماعی
                      </h4>
                      {/* <!--text--> */}
                      <p className='pop-up-text'>
                        لطفا آیدی تلگرام خود را با دقت وارد کنید
                      </p>
                      {/* <!--content--> */}
                      <div className='pop-up-form-wrapper'>
                        <form action='#' className='pop-up-form-v4'>
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='text'
                            className='pop-up-form-input'
                            placeholder='تلگرام :'
                          />
                          {/* <!--buttons-wrapper--> */}
                          <div className='pop-up-buttons-wrapper'>
                            {/* <!--button--> */}
                            <label
                              for='pop-up-telegram-input'
                              className='pop-up-button pop-up-white-button'
                            >
                              بازگشت و انصراف
                            </label>
                            {/* <!--button--> */}
                            <button
                              type='submit'
                              className='pop-up-button pop-up-blue-button'
                            >
                              ویرایش و تغییر اطلاعات
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <!--instagram--> */}
                    <div className='pop-up-window instagram-pop-up-window'>
                      {/* <!--text--> */}
                      <h4 className='pop-up-title'>
                        ثبت | ویرایش شبکه اجتماعی
                      </h4>
                      {/* <!--text--> */}
                      <p className='pop-up-text'>
                        لطفا آیدی اینستاگرام خود را با دقت وارد کنید
                      </p>
                      {/* <!--content--> */}
                      <div className='pop-up-form-wrapper'>
                        <form action='#' className='pop-up-form-v4'>
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='text'
                            className='pop-up-form-input'
                            placeholder='اینستاگرام :'
                          />
                          {/* <!--buttons-wrapper--> */}
                          <div className='pop-up-buttons-wrapper'>
                            {/* <!--button--> */}
                            <label
                              for='pop-up-instagram-input'
                              className='pop-up-button pop-up-white-button'
                            >
                              بازگشت و انصراف
                            </label>
                            {/* <!--button--> */}
                            <button
                              type='submit'
                              className='pop-up-button pop-up-blue-button'
                            >
                              ویرایش و تغییر اطلاعات
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <ToastContainer toastClassName='toast-right' />
    </div>
  )
}

export default Authentication
