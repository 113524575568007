import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/style.css";
import "../assets/css/fonts.css";
import "../assets/css/media-query.css";
import "../assets/css/variables.css";
import { useNavigate } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// آیکون
import {
  FaChevronDown,
  FaPercent,
  FaShoppingBag,
  FaPlay,
  FaClock,
  FaMusic,
  FaExclamationTriangle,
  FaPause,
  FaPercentage,
  FaArrowUp,
} from "react-icons/fa";

import {
  FaStore,
  FaCaretSquareDown,
  FaChevronCircleRight,
  FaChevronCircleLeft,
  FaPhoneVolume,
  FaAccessibleIcon,
  FaNewspaper,
  FaTh,
  FaCalendarAlt,
  FaComments,
  FaThumbsUp,
  FaShieldAlt,
  FaStar,
  FaTelegram,
  FaWhatsapp,
} from "react-icons/fa";

import { IoMdMenu } from "react-icons/io";
import { FaRegWindowClose } from "react-icons/fa";
import { CiLogin } from "react-icons/ci";
import { RiShoppingBasketFill } from "react-icons/ri";
import { useLocation } from "react-router-dom";

//   عکس ها

import logo from "../assets/images/nav-v2-logo.png";
import vectorImage from "../assets/images/Vector.png";
import logoImage from "../assets/images/logo.png";
import productImage from "../assets/images/product-page-main-img.jpg";
import bagtick from "../assets/images/bag-tick.png";
import banner from "../assets/images/expert-banner-img.png";
import comment from "../assets/images/comment-profile-img.png";
import footerlogo from "../assets/images/svg-logo.png";
import footerImg01 from "../assets/images/footer-img-1.png";
import footerImg02 from "../assets/images/footer-img-2.png";
import footerImg03 from "../assets/images/footer-img-3.png";
import logo02 from "../assets/images/logo.png";

function ProductDetail() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cardId = queryParams.get("id");
  // ----------------
  // ----------------
  const [cartCount, setCartCount] = useState(0);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [productData, setProductData] = useState(null);

  const [loading, setLoading] = useState(true);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedValue, setSelectedValue] = useState("انتخاب مقدار");
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [selectedProductId, setSelectedProductId] = useState(null);

  // محصول مرتبط

  const [relatedProductData1, setRelatedProductData1] = useState(null);
  const [relatedProductData2, setRelatedProductData2] = useState(null);

  // محصول 1

  const [relatedCountry1, setRelatedCountry1] = useState([]);
  const [selectedCountry1, setSelectedCountry1] = useState(null);
  const [selectedValue1, setSelectedValue1] = useState("انتخاب مقدار");
  const [selectedPrice1, setSelectedPrice1] = useState(0);
  const [selectedProductId1, setSelectedProductId1] = useState(null);

  //   ------------------------------

  //   محصول 2

  const [relatedCountry2, setRelatedCountry2] = useState([]);
  const [selectedCountry2, setSelectedCountry2] = useState(null);
  const [selectedValue2, setSelectedValue2] = useState("انتخاب مقدار");
  const [selectedPrice2, setSelectedPrice2] = useState(0);
  const [selectedProductId2, setSelectedProductId2] = useState(null);

  //   اسلایدر

  const banners = [
    {
      imgSrc:
        "https://www.yellowjayco.com//uploads/f626b274f226a483d96627c1a36a94e1e1f9fdef.png",
      name: "محمدرضا ",
      phone: "۰۹۱۶۰۲۶۵۶۶۱",
    },
    {
      imgSrc:
        "https://www.yellowjayco.com//uploads/bbde0a0f465d8f99ddfc29d8e08f492323534e1d.png",
      name: "محمدرضا عبادی",
      phone: "۰۹۱۶۰۲۶۵۶۶۱",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [exitAnimation, setExitAnimation] = useState(false);

  const handlePrevClick = () => {
    if (!isAnimating) {
      setExitAnimation(true);
      setTimeout(() => {
        const newIndex =
          currentIndex === 0 ? banners.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
        setIsAnimating(true);
        setExitAnimation(false);
      }, 500);
    }
  };

  const handleNextClick = () => {
    if (!isAnimating) {
      setExitAnimation(true);
      setTimeout(() => {
        const newIndex =
          currentIndex === banners.length - 1 ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
        setIsAnimating(true);
        setExitAnimation(false);
      }, 500);
    }
  };

  const handleAnimationEnd = () => {
    setIsAnimating(false);
  };

  //   فراخوانی پست وبلاگ
  const [blogData, setBlogData] = useState(null);

  const fetchBlogData = async () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://steelblue-ferret-758809.hostingersite.com/api/blog/category/2',
      headers: {},
    }

    try {
      const response = await axios.request(config);
      setBlogData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBlogData(); // Fetch cart data on component mount
  }, []);

  //   فراخوانی سبد خرید

  const fetchCartData = () => {
    const accessToken = sessionStorage.getItem("access_token"); // Get token from sessionStorage

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://steelblue-ferret-758809.hostingersite.com/api/cart',
      headers: {
        Authorization: `Bearer ${accessToken}`, // Use the token from sessionStorage
      },
    }

    axios
      .request(config)
      .then((response) => {
        setCartCount(response.data.data.products.length); // Update the product card number
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 404 &&
          error.response.data.message === "Unauthenticated."
        ) {
          toast.error("برای دیدن جزیات لطفا وارد شوید ");
        } else {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    fetchCartData(); // Fetch cart data on component mount
  }, []);

  //  --------------------------------------------------- محصول مرتبط

  //    ------------------------محصول اول--------------------
  useEffect(() => {
    const fetchRandomProductData1 = async () => {
      const randomId = Math.floor(Math.random() * 3) + 1; // Generate a random number between 1 and 8
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://steelblue-ferret-758809.hostingersite.com/api/categories/info/${randomId}`,
        headers: {
          Accept: 'application/json',
        },
      }

      try {
        const response = await axios.request(config);
        setRelatedProductData1(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchRandomProductData1();
  }, []);

  useEffect(() => {
    if (
      relatedProductData1 &&
      relatedProductData1.data &&
      relatedProductData1.data.countires
    ) {
      setRelatedCountry1(relatedProductData1.data.countires);
    }
  }, [relatedProductData1]);

  //   انتخاب کشور برای محصول اول
  const handleCountryChange1 = (country) => {
    setSelectedCountry1(country);
    setSelectedPrice1(0);
    setSelectedProductId1(null);
  };

  //   انتخاب مقدار برای محصول اول
  const handleValueSelect1 = (value, price, productId) => {
    setSelectedValue1(value);
    setSelectedPrice1(price);
    setSelectedProductId1(productId);
  };

  //   نمایش مقدار ها برای محصول اول
  const renderBalances1 = () => {
    if (!selectedCountry1) {
      return <li className="product-price-drop-item">بدون مقدار</li>;
    }

    if (relatedProductData1.data.type === "gift_card") {
      return selectedCountry1.giftCardRows.map((row, index) => (
        <li
          key={index}
          className="product-price-drop-item"
          onClick={() =>
            handleValueSelect1(
              `${row.balance} ${selectedCountry1.money}`,
              row.price,
              row.product_id
            )
          }
        >
          {toPersianNumber(row.balance)} {selectedCountry1.money}
        </li>
      ));
    } else if (relatedProductData1.data.type === "license") {
      return selectedCountry1.licenses.map((license, index) => (
        <li
          key={index}
          className="product-price-drop-item"
          onClick={() =>
            handleValueSelect1(
              `${license.duration} ماهه`,
              license.price,
              license.product_id
            )
          }
        >
          {toPersianNumber(license.duration)} ماهه
        </li>
      ));
    }
  };

  //   هندل اضافه به سبد خرید برای مجصول اول

  const handleAddToCart1 = () => {
    const accessToken = sessionStorage.getItem("access_token");
    const data = "";

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://steelblue-ferret-758809.hostingersite.com/api/cart/add/${selectedProductId1}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "Out of product") {
            toast.error("محصول موجودی ندارد");
          } else if (response.data.message === "Add product to cart") {
            toast.success("با موفقیت به سبد اضافه شد");
            // این بخش را بر اساس نیاز خود می‌توانید تغییر دهید
          }
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 404 &&
          error.response.data.message === "Unauthenticated."
        ) {
          toast.error("لطفا مجدد وارد حساب شوید");
        } else {
          console.log(error);
        }
      });
  };

  //    ------------------------محصول دوم--------------------

  useEffect(() => {
    const fetchRandomProductData2 = async () => {
      const randomId = Math.floor(Math.random() * 3) + 1; // Generate a random number between 1 and 8
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://steelblue-ferret-758809.hostingersite.com/api/categories/info/${randomId}`,
        headers: {
          Accept: 'application/json',
        },
      }

      try {
        const response = await axios.request(config);
        setRelatedProductData2(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchRandomProductData2();
  }, []);

  useEffect(() => {
    if (
      relatedProductData2 &&
      relatedProductData2.data &&
      relatedProductData2.data.countires
    ) {
      setRelatedCountry2(relatedProductData2.data.countires);
    }
  }, [relatedProductData2]);

  const handleCountryChange2 = (country) => {
    setSelectedCountry2(country);
    setSelectedPrice2(0);
    setSelectedProductId2(null);
  };

  const handleValueSelect2 = (value, price, productId) => {
    setSelectedValue2(value);
    setSelectedPrice2(price);
    setSelectedProductId2(productId);
  };

  const renderBalances2 = () => {
    if (!selectedCountry2) {
      return <li className="product-price-drop-item">بدون مقدار</li>;
    }

    if (relatedProductData2.data.type === "gift_card") {
      return selectedCountry2.giftCardRows.map((row, index) => (
        <li
          key={index}
          className="product-price-drop-item"
          onClick={() =>
            handleValueSelect2(
              `${row.balance} ${selectedCountry2.money}`,
              row.price,
              row.product_id
            )
          }
        >
          {toPersianNumber(row.balance)} {selectedCountry2.money}
        </li>
      ));
    } else if (relatedProductData2.data.type === "license") {
      return selectedCountry2.licenses.map((license, index) => (
        <li
          key={index}
          className="product-price-drop-item"
          onClick={() =>
            handleValueSelect2(
              `${license.duration} ماهه`,
              license.price,
              license.product_id
            )
          }
        >
          {toPersianNumber(license.duration)} ماهه
        </li>
      ));
    }
  };

  const handleAddToCart2 = () => {
    const accessToken = sessionStorage.getItem("access_token");
    const data = "";

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://steelblue-ferret-758809.hostingersite.com/api/cart/add/${selectedProductId2}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "Out of product") {
            toast.error("محصول موجودی ندارد");
          } else if (response.data.message === "Add product to cart") {
            toast.success("با موفقیت به سبد اضافه شد");
            // این بخش را بر اساس نیاز خود می‌توانید تغییر دهید
          }
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 404 &&
          error.response.data.message === "Unauthenticated."
        ) {
          toast.error("لطفا مجدد وارد حساب شوید");
        } else {
          console.log(error);
        }
      });
  };

  //   ----------------------------------------------------------------

  //   هندل
  const handleBasketClick = () => {
    navigate("/card-page");
  };

  //   هندل انتخاب کشور

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setSelectedPrice(0);
    setSelectedProductId(null);
  };

  //   هندل انتخاب مقدار

  const handleValueSelect = (value, price, productId) => {
    setSelectedValue(value);
    setSelectedPrice(price);
    setSelectedProductId(productId);
  };

  //   هندل نمایش ریجن

  const renderBalances = () => {
    if (!selectedCountry) {
      return <li className="product-price-drop-item">بدون مقدار</li>;
    }

    if (productData.data.type === "gift_card") {
      return selectedCountry.giftCardRows.map((row, index) => (
        <li
          key={index}
          className="product-price-drop-item"
          onClick={() =>
            handleValueSelect(
              `${row.balance} ${selectedCountry.money}`,
              row.price,
              row.product_id
            )
          }
        >
          {toPersianNumber(row.balance)} {selectedCountry.money}
        </li>
      ));
    } else if (productData.data.type === "license") {
      return selectedCountry.licenses.map((license, index) => (
        <li
          key={index}
          className="product-price-drop-item"
          onClick={() =>
            handleValueSelect(
              `${license.duration} ماهه`,
              license.price,
              license.product_id
            )
          }
        >
          {toPersianNumber(license.duration)} ماهه
        </li>
      ));
    }
  };

  //   تبدیل به فارسی

  const toPersianNumber = (number) => {
    const persianDigits = "۰۱۲۳۴۵۶۷۸۹";
    return number.toString().replace(/\d/g, (digit) => persianDigits[digit]);
  };

  //   نمایش 3 رقم

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //   navigate
  const navigate = useNavigate();

  //   هندل کلیک روی پروفایل

  const handleProfileClick = (event) => {
    event.preventDefault(); // جلوگیری از رفتار پیش فرض لینک
    const accessToken = sessionStorage.getItem("access_token");
    if (accessToken) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  //   دریافت اطلاعات محصول با ID

  useEffect(() => {
    const fetchCardData = async () => {
      setLoading(true);
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://steelblue-ferret-758809.hostingersite.com/api/categories/info/${cardId}`,
        headers: {
          Accept: 'application/json',
        },
      }

      try {
        const response = await axios.request(config);
        setProductData(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCardData();
  }, [cardId]);

  //   افزودن به سبد خرید

  // چک کردن برای توکن

  const accessToken = sessionStorage.getItem("access_token");
  if (!accessToken) {
    console.error("Access token not found");
    return;
  }

  // ---

  const handleAddToCart = () => {
    const accessToken = sessionStorage.getItem("access_token"); // Get token from sessionStorage
    const data = "";

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://api.zoodgift.com/api/cart/add/${selectedProductId}`, // Use selectedProductId here
      headers: {
        Authorization: `Bearer ${accessToken}`, // Use the token from sessionStorage
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "Out of product") {
            toast.error("محصول موجودی ندارد");
          } else if (response.data.message === "Add product to cart") {
            toast.success("با موفقیت به سبد اضافه شد");
            setCartCount((prevCount) => prevCount + 1); // Increment cart count
          }
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 404 &&
          error.response.data.message === "Unauthenticated."
        ) {
          toast.error("لطفا مجدد وارد حساب شوید");
        } else {
          console.log(error);
        }
      });
  };

  // اجرای لودینگ

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <div className="body-white-color-2">
      <div className="page-main-container body-white-color-2">
        {/* <!--header-top--> */}
        <div className="header-top-main-wrapper">
          {/* <!--input-for-controlling-the-functionality--> */}
          <input
            type="checkbox"
            className="mobile-nav-input mobile-nav-input-v2"
            id="mobile-nav-check"
            hidden
          />
          <div className="header-top-wrapper">
            {/* <!--setting-contaienr--> */}
            <div className="container">
              {/* <!--navigation--> */}
              <nav className="navigation-v2">
                {/* <!--logo--> */}
                <a href="/" className="nav-v2-logo-wrapper">
                  {/* <!--img--> */}
                  <img src={logo} alt="Logo" className="nav-v2-logo" />
                </a>
                {/* <!--list--> */}
                <ul className="nav-v2-list">
                  {/* <!--item--> */}
                  <li className="nav-v2-item">
                    <a href="./product-page.html" className="nav-v2-item-link">
                      لیست گیفت کارت
                    </a>
                  </li>
                  {/* <!--item--> */}
                  <li className="nav-v2-item">
                    <a href="./teach-page.html" className="nav-v2-item-link">
                      آموزش استفاده گیفت کارت
                    </a>
                  </li>
                  {/* <!--item--> */}
                  <li className="nav-v2-item">
                    <a href="./blog.html" className="nav-v2-item-link">
                      بلاگ
                    </a>
                  </li>
                  {/* <!--item--> */}
                  <li className="nav-v2-item">
                    <a href="./error-404.html" className="nav-v2-item-link">
                      تماس با ما
                    </a>
                  </li>
                </ul>
                {/* <!--==mobile-nav==--> */}
                {/* <!--open-icon--> */}
                <label
                  htmlFor="mobile-nav-check"
                  className="mobile-nav-open-label mobile-nav-btns"
                >
                  <IoMdMenu className="fa-solid fa-bars open-nav-icon" />
                </label>

                {/* <!--buttons--> */}
                <div className="nav-v2-links-wrapper">
                  <div className="shop-link-v2-wrapper">
                    {/* <!--number--> */}
                    <span className="shop-link-number">
                      {cartCount.toLocaleString("fa-IR")}
                    </span>
                    {/* <!--link(Shop-link)--> */}
                    <a
                      href="/card-page"
                      className="nav-v2-link nav-v2-shop-link"
                    >
                      {/* <!--icon--> */}
                      <i className="fa-solid fa-basket-shopping"></i>
                      <RiShoppingBasketFill
                        className="fa-solid fa-basket-shopping"
                        onClick={handleBasketClick}
                      />
                    </a>
                  </div>
                  {/* <!--link(login-link)--> */}
                  <a
                    href="/"
                    className="nav-v2-link nav-v2-Login-link"
                    onClick={handleProfileClick}
                  >
                    {isLoggedIn ? "داشبورد" : "ورود به حساب"}
                  </a>
                  {/* <!--login--> */}
                  <a
                    href="./login.html"
                    className="nav-login-btn nav-v2-responsive-login"
                  >
                    <span className="login-text">ورود</span>

                    <CiLogin className="fa-solid fa-arrow-right-to-bracket nav-login-icon" />
                  </a>
                </div>
              </nav>
            </div>
          </div>
          {/* <!--nav-list--> */}
          <ul className="mobile-nav-list">
            {/* <!--close-icon--> */}
            <label
              htmlFor="mobile-nav-check"
              className="mobile-nav-close-label mobile-nav-btns"
            >
              <FaRegWindowClose className="fa-solid fa-x open-close-icon" />
            </label>
            {/* <!--nav-item--> */}
            <li className="mobile-nav-item">
              <a href="./index-2.html" className="mobile-nav-link">
                خانه
              </a>
            </li>
            {/* <!--nav-item--> */}
            <li className="mobile-nav-item">
              <a href="./product-page.html" className="mobile-nav-link">
                گیفت کارت
              </a>
            </li>
            {/* <!--nav-item--> */}
            <li className="mobile-nav-item">
              <a href="./teach-page.html" className="mobile-nav-link">
                آموزش استفاده گیفت کارت
              </a>
            </li>
            {/* <!--nav-item--> */}
            <li className="mobile-nav-item">
              <a href="./error-404.html" className="mobile-nav-link">
                راهنمای خرید گیفت کارت
              </a>
            </li>
            {/* <!--nav-item--> */}
            <li className="mobile-nav-item">
              <a href="./blog.html" className="mobile-nav-link">
                بلاگ
              </a>
            </li>
            {/* <!--nav-item--> */}
            <li className="mobile-nav-item">
              <a href="./error-404.html" className="mobile-nav-link">
                تماس با ما
              </a>
            </li>
          </ul>
        </div>

        {/* <!--==========Main=========--> */}
        <main className="main">
          {/* <!--=========product-content-section===========--> */}
          <section className="product-content-section" id="header-v2">
            {/* product-content-section */}
            <div className="container margin-top-super-big">
              {/* setting-container */}
              <p className="addres-text product-content-addres-text">
                {/* title */}
                خانه/گیفت کارت پلی اسیتشن
              </p>
              <div className="product-content-wrapper">
                {/* main-wrapper */}
                <div className="product-box-v1 product-wrapper-v1">
                  {/* product--box */}
                  <div className="product-title-img">
                    {/* title-image */}
                    <img
                      src={vectorImage}
                      alt="img"
                      className="product-vector-img"
                    />
                    {/* vector */}
                    <img
                      src={logoImage}
                      alt="img"
                      className="product-logo-img"
                    />
                    {/* logo */}
                  </div>
                  <div className="product-first-row product-box-img-box">
                    {/* img-box */}
                    <h4 className="card-text">
                      {/* caption */}
                      <span className="card-title">
                        {productData.data.title}
                      </span>
                      {/* title */}
                      <span className="card-title-eng">
                        {productData.data.en_title}
                      </span>
                    </h4>
                    <div className="product-img-wrapper-v2">
                      {/* img-wrapper */}
                      <img
                        src={productData.data.image}
                        alt="img"
                        className="product-img"
                      />
                      {/* img */}
                    </div>
                  </div>
                  <div className="product-second-row choose-content-wrapper">
                    {/* chooose-box */}
                    <div className="choose-region-wrapper choose-wrapper">
                      {/* choose-regon-wrapper */}
                      <h5 className="product-choose-title">انتخاب ریجن</h5>
                      {/* title */}
                      <div className="choose-options-wrapper">
                        {productData.data.countires.length > 0 ? (
                          productData.data.countires.map((country, index) => (
                            <React.Fragment key={country.id}>
                              <input
                                type="radio"
                                name="region-radio"
                                className="choose-input"
                                id={`choose-${index + 1}`}
                                hidden
                                onChange={() => handleCountryChange(country)}
                              />
                              <label
                                htmlFor={`choose-${index + 1}`}
                                className="choose-label"
                              >
                                {country.title || "بدون مقدار"}
                              </label>
                            </React.Fragment>
                          ))
                        ) : (
                          <label className="choose-label">بدون مقدار</label>
                        )}
                      </div>
                    </div>
                    <div className="choose-price-wrapper choose-wrapper">
                      {/* choose-Price-wrapper */}
                      <h5 className="product-choose-title">انتخاب مقدار:</h5>
                      {/* title */}
                      <input
                        type="checkbox"
                        className="product-price-input-v1"
                        id="product-price-input-id"
                        hidden
                      />
                      {/* input-for-controlling-the-functionality */}
                      <label
                        htmlFor="product-price-input-id"
                        className="product-pric-label"
                      >
                        <p className="product-price-drop-text">
                          {toPersianNumber(selectedValue)}
                          {/* icon */}
                          <FaChevronDown className="fa-solid fa-chevron-down product-chevron-down-icon" />
                        </p>
                        <ul className="product-price-drop-list">
                          {renderBalances()}
                        </ul>
                      </label>
                    </div>
                  </div>
                  <div className="product-third-row product-pay-box">
                    {/* product-pay-box */}
                    <div className="offer-text-box-wrapper">
                      {/* off-box */}
                      <p className="offer-main-text-v1">
                        {/* text */}
                        ۳۵۰,۰۰۰
                      </p>
                      <p className="offer-text-v1">
                        {/* text */}
                        <span className="offer-percent-box">
                          {/* percent-box */}

                          <FaPercentage className="fa-solid fa-percent" />
                          {/* icon */}
                        </span>
                        <span className="offer-number-v1">
                          {/* offer-number */}
                          10
                        </span>
                      </p>
                    </div>
                    <p className="product-total-price-text">
                      {/* text */}
                      <span className="total-price-main-text">
                        مجموع فاکتور شما :
                      </span>
                      <span className="total-price-sub-text">
                        {toPersianNumber(formatNumberWithCommas(selectedPrice))}
                        <span className="total-price-main-text">تومان</span>
                      </span>
                    </p>
                    <button
                      className="factor-btn btn-secondary-v1"
                      onClick={handleAddToCart}
                    >
                      افزودن به سبد خرید
                      {/* button */}
                      <FaShoppingBag className="factor-btn-icon btn-secondary-v1-icon" />
                      {/* icon */}
                    </button>
                    <div className="more-option-link-wrapper">
                      {/* more-option-box */}
                      <a href="#" className="more-opiton-link">
                        {/* link */}
                        <FaPlay className="more-option-link-icon" />
                        {/* icon */}
                        راهنمای خرید محصول
                      </a>
                      <a href="#" className="more-opiton-link">
                        {/* link */}
                        <FaClock className="more-option-link-icon" />
                        {/* icon */}
                        تحویل محصول فوری
                      </a>
                    </div>
                    <div className="warning-box">
                      {/* warning */}
                      <h4 className="warning-box-title">
                        {/* title */}
                        <FaExclamationTriangle className="fa-solid fa-triangle-exclamation warning-box-icon" />
                        {/* icon */}
                        لطفا در انتخاب محصول دقت فرمایید امکان برگشت محصول وجود
                        ندارد
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!--=========Product-main-content-wrapper==========--> */}
          <section className="product-main-content-section">
            {/* <!--setting-container--> */}
            <div className="container">
              {/* <!--title--> */}
              <h4 class="h4-title product-title">
                {/* <!--icon--> */}
                <i class="fa-solid fa-store product-title-text"></i>
                {/* <!--text--> */}
                محصولات مرتبط
              </h4>
              {/* <!--main-wrapper--> */}
              <div className="product-main-content-wrapper">
                {/* <!--right-side--> */}
                <div className="product-main-content-right-side">
                  {/* <!--same-procuct-section--> */}
                  <section className="same-product-section-v1">
                    {/* ------------محصول اول -------------------- */}
                    <input
                      type="checkbox"
                      className="same-product-input"
                      id="same-product-check-1"
                      hidden
                    />
                    <div className="same-product-box-v1 product-box-style">
                      <div className="same-product-box-v1-top">
                        <input
                          type="checkbox"
                          className="same-product-buy-input"
                          id="same-product-buy-check"
                          hidden
                        />
                        <div className="same-product-box-v1-top-right">
                          <div className="product-shop-box-v1">
                            <img
                              src={bagtick}
                              alt="img"
                              className="product-shop-box-v1-icon"
                            />
                          </div>
                          <div className="product-img-and-title-wrapper">
                            <img
                              src={relatedProductData1.data.image}
                              alt="img"
                              className="same-product-img-v1"
                            />
                            <div className="same-product-top-text-box">
                              <h4 className="same-product-title">
                                {relatedProductData1.data.title}
                              </h4>
                              <label
                                htmlFor="same-product-check-1"
                                className="same-product-see-more-deatails-label"
                              >
                                مشاهده جزئیات
                                <i className="fa-regular fa-square-caret-down"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="same-product-box-v1-left-wrapper">
                          <p className="same-product-price">
                            <span className="same-product-price-number">
                              {toPersianNumber(
                                formatNumberWithCommas(selectedPrice1)
                              )}
                            </span>
                            قیمت
                          </p>
                          <label
                            htmlFor="same-product-buy-check"
                            className="same-product-add-to-cart-btn"
                            onClick={handleAddToCart1}
                          >
                            افزودن به سبد خرید
                          </label>
                        </div>
                      </div>
                      <div className="same-product-box-v1-bottom">
                        <span className="same-product-line"></span>
                        <div className="same-product-option-wrapper">
                          <div className="choose-region-wrapper choose-wrapper">
                            <h5 className="product-choose-title">
                              انتخاب ریجن
                            </h5>
                            <div className="choose-options-wrapper same-product-choose-option-wrapper">
                              {relatedCountry1.length > 0 ? (
                                relatedCountry1.map((country, index) => (
                                  <React.Fragment key={country.id}>
                                    <input
                                      type="radio"
                                      name="region-radio-related"
                                      className="choose-input"
                                      id={`choose-related-${country.id}`}
                                      hidden
                                      defaultChecked={index === 0}
                                      onChange={() =>
                                        handleCountryChange1(country)
                                      }
                                    />
                                    <label
                                      htmlFor={`choose-related-${country.id}`}
                                      className="choose-label same-product-choose-lable"
                                    >
                                      {country.title}
                                    </label>
                                  </React.Fragment>
                                ))
                              ) : (
                                <React.Fragment>
                                  <input
                                    type="radio"
                                    name="region-radio-related"
                                    className="choose-input"
                                    id="choose-related-none"
                                    hidden
                                    defaultChecked
                                  />
                                  <label
                                    htmlFor="choose-related-none"
                                    className="choose-label same-product-choose-lable"
                                  >
                                    بدون مقدار
                                  </label>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                          <div className="length-time-wrapper">
                            <h5 className="product-choose-title">
                              انتخاب مقدار
                            </h5>
                            <input
                              type="checkbox"
                              className="charity-input"
                              id="charity-check"
                            />
                            <label
                              htmlFor="charity-check"
                              className="charity-label product-page-drop-down"
                            >
                              <p className="charity-text">
                                {toPersianNumber(selectedValue1)}
                                <i className="fa-regular fa-square-caret-down charity-icon"></i>
                              </p>
                              <ul className="charity-price-list">
                                {renderBalances1()}
                              </ul>
                            </label>
                          </div>
                        </div>
                        <span className="same-product-line"></span>
                        <p className="same-product-paragraph">
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                          چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون
                          بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،
                          <span className="same-product-see-more">
                            توضیح بیشتر{" "}
                            <span className="same-product-dots">...</span>
                          </span>
                        </p>
                      </div>
                    </div>

                    {/* ------------محصول دوم -------------------- */}
                    <input
                      type="checkbox"
                      className="same-product-input"
                      id="same-product-check-2"
                      hidden
                    />
                    <div className="same-product-box-v1 product-box-style">
                      <div className="same-product-box-v1-top">
                        <input
                          type="checkbox"
                          className="same-product-buy-input"
                          id="same-product-buy-check-2"
                          hidden
                        />
                        <div className="same-product-box-v1-top-right">
                          <div className="product-shop-box-v1">
                            <img
                              src={bagtick}
                              alt="img"
                              className="product-shop-box-v1-icon"
                            />
                          </div>
                          <div className="product-img-and-title-wrapper">
                            <img
                              src={relatedProductData2.data.image}
                              alt="img"
                              className="same-product-img-v1"
                            />
                            <div className="same-product-top-text-box">
                              <h4 className="same-product-title">
                                {relatedProductData2.data.title}
                              </h4>
                              <label
                                htmlFor="same-product-check-2"
                                className="same-product-see-more-deatails-label"
                              >
                                مشاهده جزئیات
                                <i className="fa-regular fa-square-caret-down"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="same-product-box-v1-left-wrapper">
                          <p className="same-product-price">
                            <span className="same-product-price-number">
                              {toPersianNumber(
                                formatNumberWithCommas(selectedPrice2)
                              )}
                            </span>
                            قیمت
                          </p>
                          <label
                            htmlFor="same-product-buy-check-2"
                            className="same-product-add-to-cart-btn"
                            onClick={handleAddToCart2}
                          >
                            افزودن به سبد خرید
                          </label>
                        </div>
                      </div>
                      <div className="same-product-box-v1-bottom">
                        <span className="same-product-line"></span>
                        <div className="same-product-option-wrapper">
                          <div className="choose-region-wrapper choose-wrapper">
                            <h5 className="product-choose-title">
                              انتخاب ریجن
                            </h5>
                            <div className="choose-options-wrapper same-product-choose-option-wrapper">
                              {relatedCountry2.length > 0 ? (
                                relatedCountry2.map((country, index) => (
                                  <React.Fragment key={country.id}>
                                    <input
                                      type="radio"
                                      name="region-radio-related-2"
                                      className="choose-input"
                                      id={`choose-related-2-${country.id}`}
                                      hidden
                                      defaultChecked={index === 0}
                                      onChange={() =>
                                        handleCountryChange2(country)
                                      }
                                    />
                                    <label
                                      htmlFor={`choose-related-2-${country.id}`}
                                      className="choose-label same-product-choose-lable"
                                    >
                                      {country.title}
                                    </label>
                                  </React.Fragment>
                                ))
                              ) : (
                                <React.Fragment>
                                  <input
                                    type="radio"
                                    name="region-radio-related-2"
                                    className="choose-input"
                                    id="choose-related-2-none"
                                    hidden
                                    defaultChecked
                                  />
                                  <label
                                    htmlFor="choose-related-2-none"
                                    className="choose-label same-product-choose-lable"
                                  >
                                    بدون مقدار
                                  </label>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                          <div className="length-time-wrapper">
                            <h5 className="product-choose-title">
                              انتخاب مقدار
                            </h5>
                            <input
                              type="checkbox"
                              className="charity-input"
                              id="charity-check-2"
                            />
                            <label
                              htmlFor="charity-check-2"
                              className="charity-label product-page-drop-down"
                            >
                              <p className="charity-text">
                                {toPersianNumber(selectedValue2)}
                                <i className="fa-regular fa-square-caret-down charity-icon"></i>
                              </p>
                              <ul className="charity-price-list">
                                {renderBalances2()}
                              </ul>
                            </label>
                          </div>
                        </div>
                        <span className="same-product-line"></span>
                        <p className="same-product-paragraph">
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                          چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون
                          بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،
                          <span className="same-product-see-more">
                            توضیح بیشتر{" "}
                            <span className="same-product-dots">...</span>
                          </span>
                        </p>
                      </div>
                    </div>
                  </section>
                  <div className="product-blog-content product-box-style">
                    {/* video-section */}
                    <section className="product-video-section">
                      {/* title */}
                      <h4 className="h4-title product-title">
                        گیفت کارت پلی استیشن چیست ؟
                      </h4>
                      <p className="product-sub-title-text">
                        پرداخت و خرید آسان از پلی اسیتشن با گیفت کارت پلی اسیتشن
                      </p>
                      {/* video-wrapper */}
                      <div className="video-wrapper">
                        {/* input-for-controlling-the-functionality */}
                        <input
                          type="checkbox"
                          className="video-input"
                          id="video-click"
                        />
                        {/* video-banner */}
                        <label htmlFor="video-click" className="video-banner">
                          {/* img */}
                          <img
                            src="./assets/images/video-banner.png"
                            alt="img"
                            className="video-banner-img"
                          />
                          {/* video-banner-play-img */}
                          <img
                            src="./assets/images/play-background-icon.png"
                            className="video-banner-play-img"
                            alt="img"
                          />
                          {/* banner-text-wrapper */}
                          <div className="video-banner-text-box">
                            {/* text */}
                            <p className="video-banner-text">
                              آموزش شارژ حساب کلش اف کلنز
                            </p>
                            {/* text */}
                            <p className="video-banner-text">
                              مدت زمان ویدیو : 38 ثانیه
                            </p>
                          </div>
                        </label>
                        {/* video */}
                        <video
                          src="./assets/video/Mountain Golem Awakens ⛰️ Clash of Clans Official.mp4"
                          className="video-wrapper-video"
                          controls
                        ></video>
                      </div>
                    </section>
                    {/* info-section */}
                    <section className="product-blog-info-section">
                      {/* title */}
                      <h4 className="h4-title">
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                      </h4>
                      {/* text */}
                      <p className="paragraph">
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                        چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون
                        بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و
                        برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با
                        هدف بهبود ابزارهای کاربردی لورم ایپسوم متن ساختگی با
                        تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان
                        گرافیک است
                      </p>
                      {/* banner */}
                      <div className="blog-info-banner">
                        {/* img */}
                        <img
                          src="./assets/images/product-blog-banner.jpg"
                          alt="img"
                          className="blog-info-img"
                        />
                      </div>
                    </section>
                    {/* blog-podcast-section */}
                    <section className="blog-podcast-section product-blog-podcast-section">
                      {/* podcast-box */}
                      <div className="podcast-box">
                        {/* text-box */}
                        <div className="podcast-text-box">
                          {/* podcast-title */}
                          <h5 className="podcast-title">
                            {/* icon */}
                            <i className="fa-solid fa-music podcast-icon"></i>
                            {/* text */}
                            نکاتی که قبل از خرید گیفت کارت پلی استیشن باید
                            بدانید
                          </h5>
                          {/* podcast-text */}
                          <div className="podcast-text">
                            زود گیفت مرجع خرید انواع گیفت کارت
                          </div>
                        </div>
                        {/* podcast-track */}
                        <div className="podcast-track-wrapper">
                          {/* input-for-controlling-the-functionality */}
                          <input
                            type="checkbox"
                            id="podcast-checkbox"
                            className="podcast-input"
                            hidden
                          />
                          {/* label */}
                          <label
                            htmlFor="podcast-checkbox"
                            className="podcast-box-label podcast-play-btn"
                          >
                            <i className="fa-solid fa-play podcast-play-icon podcast-btn-icon"></i>
                            <i className="fa-solid fa-pause podcast-pause-icon podcast-btn-icon"></i>
                          </label>
                          {/* music-loader */}
                          <div className="music-loader-box">
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                          </div>
                        </div>
                      </div>
                      {/* title */}
                      <h4 className="h4-title">
                        آموزش خرید گیفت کارت پلی استیشن از طریق سایت زود گیفت
                        ...
                      </h4>
                      {/* table-wrapper */}
                      <div className="table-wrapper">
                        {/* top */}
                        <div className="table-header-wrapper">
                          {/* table-top-text */}
                          <p className="table-text table-text-white">
                            سرعت گوشی در اجرای کلش اف کلنز
                          </p>
                          {/* table-top-text */}
                          <p className="table-text table-text-white">
                            جی ال ایکس
                          </p>
                          {/* table-top-text */}
                          <p className="table-text table-text-white">آیفون</p>
                        </div>
                        {/* table-bottom-wrapper */}
                        <div className="table-body-wrapper">
                          {/* table-top-text */}
                          <p className="table-text table-text-black">
                            قدرت اجرای بازی قوی
                          </p>
                          {/* table-top-text */}
                          <p className="table-text table-text-black">ندارد</p>
                          {/* table-top-text */}
                          <p className="table-text table-text-black">دارد</p>
                        </div>
                        {/* table-bottom-wrapper */}
                        <div className="table-body-wrapper">
                          {/* table-top-text */}
                          <p className="table-text table-text-black">
                            قدرت اجرای بازی قوی
                          </p>
                          {/* table-top-text */}
                          <p className="table-text table-text-black">ندارد</p>
                          {/* table-top-text */}
                          <p className="table-text table-text-black">دارد</p>
                        </div>
                        {/* table-bottom-wrapper */}
                        <div className="table-body-wrapper">
                          {/* table-top-text */}
                          <p className="table-text table-text-black">
                            قدرت اجرای بازی قوی
                          </p>
                          {/* table-top-text */}
                          <p className="table-text table-text-black">ندارد</p>
                          {/* table-top-text */}
                          <p className="table-text table-text-black">دارد</p>
                        </div>
                      </div>
                      {/* title */}
                      <h4 className="h4-title product-podcast-title">
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                      </h4>
                      {/* list */}
                      <ul className="podcast-list product-podcast-list">
                        {/* item */}
                        <li className="podcast-item">
                          {/* icon */}
                          <img
                            src="./assets/images/tick-square.png"
                            alt="img"
                            className="podcast-item-icon"
                          />
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                        </li>
                        {/* item */}
                        <li className="podcast-item">
                          <img
                            src="./assets/images/tick-square.png"
                            alt="img"
                            className="podcast-item-icon"
                          />
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                        </li>
                        {/* item */}
                        <li className="podcast-item">
                          <img
                            src="./assets/images/tick-square.png"
                            alt="img"
                            className="podcast-item-icon"
                          />
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                        </li>
                      </ul>
                    </section>
                    {/* yourquestion-section */}
                    <section className="product-your-question-section">
                      {/* title-wrapper */}
                      <div className="line-title-wrapper">
                        {/* title */}
                        <h4 className="line-title-text">
                          {/* icon */}
                          <i className="fa-regular fa-circle-question"></i>
                          {/* text */}
                          سوالات متداول
                        </h4>
                        {/* line */}
                        <span className="line-title-line"></span>
                      </div>
                      {/* functional-input */}
                      <input
                        type="checkbox"
                        className="question-input"
                        id="question-input-1"
                      />
                      {/* question-box */}
                      <label
                        htmlFor="question-input-1"
                        className="question-label question-label-v2"
                      >
                        {/* question */}
                        <h5 className="question-text">
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                          چاپ؟
                          {/* icon */}
                          <i className="fa-regular fa-square-caret-down question-icon question-icon-v2"></i>
                        </h5>
                        <p className="question-answer">
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                          چاپ، و با استفاده از طراحان گرافیک است،
                        </p>
                      </label>
                      {/* functional-input */}
                      <input
                        type="checkbox"
                        className="question-input"
                        id="question-input-2"
                      />
                      {/* question-box */}
                      <label
                        htmlFor="question-input-2"
                        className="question-label question-label-v2"
                      >
                        {/* question */}
                        <h5 className="question-text">
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                          چاپ؟
                          {/* icon */}
                          <i className="fa-regular fa-square-caret-down question-icon question-icon-v2"></i>
                        </h5>
                        <p className="question-answer">
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                          چاپ، و با استفاده از طراحان گرافیک است،
                        </p>
                      </label>
                    </section>
                  </div>
                </div>
                {/* <!--left-side--> */}
                <div className="product-main-content-left-side">
                  {/* slider-section */}
                  <div className="slider-x slider-width-100">
                    <div
                      className={`expert-banner ${
                        exitAnimation ? "expert-banner-exit" : ""
                      }`}
                      onAnimationEnd={handleAnimationEnd}
                      key={currentIndex}
                    >
                      <div className="expert-banner-btns">
                        <button
                          className="expert-banner-btn"
                          onClick={handleNextClick}
                        >
                          <FaChevronCircleRight className="expert-banner-btn-icon" />
                        </button>
                        <button
                          className="expert-banner-btn"
                          onClick={handlePrevClick}
                        >
                          <FaChevronCircleLeft className="expert-banner-btn-icon" />
                        </button>
                      </div>
                      <img
                        src={banners[currentIndex].imgSrc}
                        alt="img"
                        className="expert-banner-img"
                      />
                      <h5 className="expert-title">
                        <span className="expert-sub-title">کارشناسان فروش</span>
                        <span className="expert-main-title">
                          {banners[currentIndex].name}
                        </span>
                      </h5>
                      <p className="expert-number-text">
                        {banners[currentIndex].phone}
                      </p>
                      <div className="social-links-wrapper">
                        <a href="#" className="social-link">
                          <FaPhoneVolume />
                        </a>
                        <a href="#" className="social-link">
                          <FaTelegram />
                        </a>
                        <a href="#" className="social-link">
                          <FaWhatsapp />
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* product-section */}
                  <section className="product-section-v2 product-box-style">
                    {/* title */}
                    <h5 className="product-title-v2">
                      {productData.data.title}
                    </h5>
                    {/* img */}
                    <img
                      src={productData.data.image}
                      alt="img"
                      className="product-img-v2"
                    />
                    {/* choose-region-wrapper */}
                    <div className="choose-region-wrapper choose-wrapper">
                      {/* title */}
                      <h5 className="product-choose-title">انتخاب ریجن:</h5>
                      {/* wrapper */}
                      <div className="choose-options-wrapper same-product-choose-option-wrapper">
                        {/* input */}
                        {productData.data.countires.length > 0 ? (
                          productData.data.countires.map((country, index) => (
                            <React.Fragment key={country.id}>
                              <input
                                type="radio"
                                name="region-radio"
                                className="choose-input"
                                id={`choose-${index + 1}`}
                                hidden
                                onChange={() => handleCountryChange(country)}
                              />
                              <label
                                htmlFor={`choose-${index + 1}`}
                                className="choose-label"
                              >
                                {country.title || "بدون مقدار"}
                              </label>
                            </React.Fragment>
                          ))
                        ) : (
                          <label className="choose-label">بدون مقدار</label>
                        )}
                      </div>
                    </div>
                    {/* choose-price-wrapper */}
                    <div className="choose-price-wrapper-v2">
                      {/* title */}
                      <h5 className="product-choose-title">انتخاب مقدار:</h5>
                      {/* input-for-controlling-the-functionality */}
                      <input
                        type="checkbox"
                        className="product-price-input-v2"
                        id="product-price-check-v2"
                        hidden
                      />
                      {/* label */}
                      <label
                        htmlFor="product-price-check-v2"
                        className="product-price-label-v2"
                      >
                        <p className="product-price-label-text">
                          {toPersianNumber(selectedValue)}
                          {/* icon */}
                          <i className="fa-regular fa-square-caret-down product-price-label-icon"></i>
                        </p>
                        {/* list */}
                        <ul className="product-price-drop-list">
                          {renderBalances()}
                        </ul>
                      </label>
                      {/* text */}
                      <p className="product-total-price-text">
                        {/* main-text */}
                        <span className="total-price-main-text">
                          مجموع فاکتور شما :
                        </span>
                        <span className="total-price-sub-text">
                          {toPersianNumber(
                            formatNumberWithCommas(selectedPrice)
                          )}
                          <span className="total-price-main-text">تومان</span>
                        </span>
                      </p>
                      {/* button */}
                      <button
                        className="factor-btn btn-secondary-v1"
                        onClick={handleAddToCart}
                      >
                        افزودن به سبد خرید
                        {/* icon */}
                        <FaShoppingBag className="fa-solid fa-bag-shopping factor-btn-icon btn-secondary-v1-icon" />
                      </button>
                    </div>
                  </section>
                  {/* more-option-box */}
                  <div className="more-option-link-wrapper">
                    {/* link */}
                    <a href="#" className="more-opiton-link">
                      {/* icon */}
                      <FaPlay className="fa-solid fa-play more-option-link-icon" />
                      {/* text */}
                      راهنمای خرید محصول
                    </a>
                    <a href="#" className="more-opiton-link">
                      {/* icon */}
                      <FaClock className="fa-regular fa-clock more-option-link-icon" />
                      {/* text */}
                      تحویل محصول فوری
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!--==========same-articles-section=========--> */}
          <section className="same-articles-section product-same-article-section">
            <div className="container">
              <h4 className="h4-title">
                <FaNewspaper />
                مقالات مرتبط
              </h4>
              <div className="product-same-article-slider slider-x slider-width-100 slider-hight-big">
                {blogData.posts.map((post) => (
                  <a href="./blog.html" className="blog-box-link" key={post.id}>
                    <div className="blog-box product-blog-box">
                      <img
                        src={post.image || "default-image-url"} // در صورت نبودن تصویر، یک تصویر پیش فرض قرار می‌دهیم
                        alt="blog-img"
                        className="blog-img product-blog-img"
                      />
                      <h5 className="h5-title prodcut-blog-title">
                        {post.title}
                      </h5>
                      <div className="blog-box-down-wrapper">
                        <p className="blog-text">
                          <FaTh />
                          {blogData.title}{" "}
                          {/* عنوان دسته‌بندی را نمایش می‌دهد */}
                        </p>
                        <p className="blog-text">
                          <FaCalendarAlt />
                          {post.created_at || "تاریخ نامشخص"}{" "}
                          {/* تاریخ ایجاد مطلب را نمایش می‌دهد */}
                        </p>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </section>

          {/* <!--=======comment-section==========--> */}
          <section className="comment-section product-comment-section">
            <div className="smaller-container">
              <div className="comment-section-wrapper">
                <h5 className="comment-section-title">
                  <FaComments className="comment-section-title-icon" />
                  دیدگاه کاربران
                </h5>
                <div className="comment-box">
                  <div className="comment-box-content-wrapper">
                    <div className="comment-profile-img">
                      <img
                        src={comment}
                        alt="img"
                        className="comment-profile-img-content"
                      />
                    </div>
                    <div className="comment-content">
                      <div className="comment-content-top">
                        <p className="comment-owner-name">
                          اصغر جعفری | کاربر مهمان
                        </p>
                        <div className="comment-top-left-wrapper">
                          <button className="comment-opinion-button">
                            <FaThumbsUp className="comment-opinion-icon like-icon" />
                            پسندیدم (۵)
                          </button>
                          <button className="comment-opinion-button">
                            <FaThumbsUp className="comment-opinion-icon deslike-icon" />
                            پسندیدم (۵)
                          </button>
                        </div>
                      </div>
                      <p className="comment-text">
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                        چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون
                        بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
                      </p>
                      <div className="comment-response-box">
                        <div className="comment-profile-img-response">
                          <img
                            src={comment}
                            alt="img"
                            className="comment-profile-img-content"
                          />
                        </div>
                        <div className="comment-content comment-content-response">
                          <div className="comment-content-top comment-content-top-responsive">
                            <p className="comment-owner-name comment-owner-response">
                              اصغر جعفری | کاربر مهمان
                            </p>
                            <div className="comment-top-left-wrapper">
                              <button className="comment-opinion-button comment-opinion-button-response">
                                <FaThumbsUp className="comment-opinion-icon like-icon" />
                                پسندیدم (۵)
                              </button>
                              <button className="comment-opinion-button comment-opinion-button-response">
                                <FaThumbsUp className="comment-opinion-icon deslike-icon" />
                                پسندیدم (۵)
                              </button>
                            </div>
                          </div>
                          <p className="comment-text comment-text-response">
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و
                            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم
                            است
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comment-box">
                  <div className="comment-box-content-wrapper">
                    <div className="comment-profile-img">
                      <img
                        src={comment}
                        alt="img"
                        className="comment-profile-img-content"
                      />
                    </div>
                    <div className="comment-content">
                      <div className="comment-content-top">
                        <p className="comment-owner-name">
                          اصغر جعفری | کاربر مهمان
                        </p>
                        <div className="comment-top-left-wrapper">
                          <button className="comment-opinion-button">
                            <FaThumbsUp className="comment-opinion-icon like-icon" />
                            پسندیدم (۵)
                          </button>
                          <button className="comment-opinion-button">
                            <FaThumbsUp className="comment-opinion-icon deslike-icon" />
                            پسندیدم (۵)
                          </button>
                        </div>
                      </div>
                      <p className="comment-text">
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                        چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون
                        بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
                      </p>
                      <div className="comment-response-box">
                        <div className="comment-profile-img-response">
                          <img
                            src={comment}
                            alt="img"
                            className="comment-profile-img-content"
                          />
                        </div>
                        <div className="comment-content comment-content-response">
                          <div className="comment-content-top comment-content-top-responsive">
                            <p className="comment-owner-name comment-owner-response">
                              اصغر جعفری | کاربر مهمان
                            </p>
                            <div className="comment-top-left-wrapper">
                              <button className="comment-opinion-button comment-opinion-button-response">
                                <FaThumbsUp className="comment-opinion-icon like-icon" />
                                پسندیدم (۵)
                              </button>
                              <button className="comment-opinion-button comment-opinion-button-response">
                                <FaThumbsUp className="comment-opinion-icon deslike-icon" />
                                پسندیدم (۵)
                              </button>
                            </div>
                          </div>
                          <p className="comment-text comment-text-response">
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و
                            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم
                            است
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="send-comment-wrapper">
                  <div className="send-comment-top">
                    <h5 className="comment-section-title margin-bottom-0">
                      <FaComments className="comment-section-title-icon" />
                      دیدگاه کاربران
                    </h5>
                    <p className="send-comment-text">
                      <FaShieldAlt className="send-comment-icon" />
                      پس از تایید ادمین نظر شما ارسال می شود
                    </p>
                  </div>
                  <form action="./error-404.html" className="comment-send-form">
                    <textarea
                      name="comment-send-text-area"
                      placeholder="نظر خود را وارد کنید"
                      className="comment-send-text-area"
                    ></textarea>
                    <div className="form-inputs-wrapper">
                      <input
                        type="text"
                        name="name"
                        placeholder="نام و نام خانوادگی"
                        className="form-input form-name-input"
                        required
                      />
                      <input
                        type="email"
                        name="email"
                        placeholder="ایمیل خود را وارد کنید"
                        className="form-input form-email-input"
                        required
                      />
                      <div className="stars-wrapper">
                        <FaStar className="stars-icon" />
                        <FaStar className="stars-icon" />
                        <FaStar className="stars-icon" />
                        <FaStar className="stars-icon" />
                        <FaStar className="stars-icon" />
                      </div>
                      <button type="submit" className="form-send-button">
                        ارسال دیدگاه
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>

        {/* <!--=========Footer==============--> */}
        <footer className="footer-v2 ">
          {/* <!--logo--> */}
          <img src={footerlogo} alt="logo" className="footer-logo" />
          {/* <!--===setting-container===--> */}
          <div className="bigger-container">
            {/* <!--footer-content-wrapper--> */}
            <div className="footer-content-wrapper-v2">
              {/* <!--footer-top-wrapper--> */}
              <div className="footer-top-wrapper-v2">
                {/* <!--right-side--> */}
                <div className="footer-top-right-wrapper-v2">
                  {/* <!--title--> */}
                  <h5 className="footer-row-title footer-row-title-v2">
                    خدمات ما
                  </h5>
                  <div className="footer-list-wrapper-v2">
                    {/* <!--list--> */}
                    <ul className="footer-list footer-list-v2">
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید گیفت کارت اپل
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید گیفت کارت پلی استیشن
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید گیفت کارت استیم{" "}
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید گیفت کارت ایکس باکس
                        </a>
                      </li>
                    </ul>
                    {/* <!--list--> */}
                    <ul className="footer-list footer-list-v2">
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید گیفت کارت گوگل پلی
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید گیفت کارت پلی استیشن پلاس
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید یوسی پابجی{" "}
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید جم کلش اف کلنز
                        </a>
                      </li>
                    </ul>
                    {/* <!--list--> */}
                    <ul className="footer-list footer-list-v2">
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید اشتراک پریمیوم تلگرام
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید اشتراک اسپاتیفای
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید لایسنس ادوبی{" "}
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید ویباکس فورتنایت
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-top-left-wrapper-v2">
                  {/* <!--title--> */}
                  <h5 className="footer-row-title footer-row-title-v2">
                    دسترسی سریع
                  </h5>
                  <div className="footer-list-wrapper-v2">
                    {/* <!--list--> */}
                    <ul className="footer-list footer-list-v2">
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./blog.html" className="footer-item-link">
                          بلاگ زود گیفت{" "}
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          شرایط و قوانین خرید{" "}
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          حساب کاربری
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          تماس با ما
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <!--footer-middle-wrapper--> */}
              <div className="footer-middle-wrapper-v2">
                {/* <!--phone-number-box--> */}
                <div className="footer-phone-number-box">
                  {/* <!--title--> */}
                  <h5 className="footer-row-title">اطلاعات تماس</h5>
                  {/* <!--footer-middle-text--> */}
                  <p className="footer-middle-text">
                    <a href="./error-404.html" className="middle-text-link">
                      ۰۶۱۵۲۳۳۳۷۹۴
                    </a>{" "}
                    |{" "}
                    <a href="./error-404.html" className="middle-text-link">
                      ۰۹۱۶۰۲۶۵۶۶۱
                    </a>
                  </p>
                </div>
                {/* <!--socila-box--> */}
                <div className="footer-social-box">
                  {/* <!--title--> */}
                  <h5 className="footer-row-title">زود گیفت در شبکه اجتماعی</h5>
                  {/* <!--footer-middle-text--> */}
                  <p className="footer-middle-text-social">
                    <a href="./error-404.html" className="middle-text-link">
                      تلگرام
                    </a>
                    <a href="./error-404.html" className="middle-text-link">
                      اینستاگرام
                    </a>
                    <a href="./error-404.html" className="middle-text-link">
                      یوتیوب
                    </a>
                  </p>
                </div>
              </div>
              {/* <!--footer-bottom-wrapper--> */}
              <div className="footer-bottom-wrapper">
                {/* <!--footer-row--> */}
                <div className="footer-row footer-big-row-v2">
                  {/* <!--title--> */}
                  <h5 className="footer-row-title-v2 footer-row-title">
                    تجربه‌ای متفاوت در خرید گیفت کارت
                  </h5>
                  {/* <!--text--> */}
                  <p className="footer-text footer-text-v2">
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                    با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی
                    تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و
                    آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم
                    افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                    طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد
                  </p>
                </div>
                {/* <!--footer-row--> */}
                <div className="footer-row footer-logo-row footer-logo-row-v2">
                  {/* <!--title--> */}
                  <h5 className="footer-row-title">نماد اعتماد </h5>
                  {/* <!--imgs-wrapper--> */}
                  <div className="footer-row-img-wrapper">
                    {/* <!--img-box--> */}
                    <div className="footer-img-box">
                      {/* <!--img--> */}
                      <img
                        src={footerImg01}
                        alt="footer-img"
                        className="footer-img"
                      />
                    </div>
                    {/* <!--img-box--> */}
                    <div className="footer-img-box">
                      {/* <!--img--> */}
                      <img
                        src={footerImg02}
                        alt="footer-img"
                        className="footer-img"
                      />
                    </div>
                    {/* <!--img-box--> */}
                    <div className="footer-img-box">
                      {/* <!--img--> */}
                      <img
                        src={footerImg03}
                        alt="footer-img"
                        className="footer-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--copy-right-wrapper--> */}
              <div className="copy-right-wrapper-v2">
                {/* <!--right-side--> */}
                <p className="copy-right-right-text">
                  {/* <!--button--> */}
                  <a href="#header-v2" className="moving-top-button">
                    {/* <!--icon--> */}

                    <FaArrowUp className="fa-solid fa-arrow-up moving-top-icon" />
                  </a>
                  کلیه حقوق مادی و معنوی این سایت متعلق به فروشگاه زود گیفت
                  می‌باشد
                </p>
                {/* <!--left-side--> */}
                <div className="copy-right-left-side-wrapper">
                  {/* <!--logo-wrapper--> */}
                  <div className="copy-right-logo-wrapper">
                    <img src={logo02} alt="logo" className="copy-right-logo" />
                  </div>
                  {/* <!--text-box---> */}
                  <div className="copy-right-left-side-text-box">
                    {/* <!--text--> */}
                    <p className="copy-right-left-main-text">
                      © 2024 ZOODGIFT.COM
                    </p>
                    {/* <!--text--> */}
                    <p className="copy-right-left-sub-text">
                      All material and intellectual rights of this site belong
                      to ZoodGift store
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <ToastContainer toastClassName="toast-right" />
    </div>
  );
}

export default ProductDetail;
