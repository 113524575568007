import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/style.css";
import "../assets/css/fonts.css";
import "../assets/css/media-query.css";
import "../assets/css/variables.css";

// image

import logo from "../assets/images/nav-v2-logo.png";
import logo02 from "../assets/images/logo.png";

import benefitImg from "../assets/images/benefits-background.png";
import cardBack from "../assets/images/card-background.png";

import { useNavigate } from "react-router-dom";

import giftlearn from "../assets/images/info-banner-v2.png";

import footerlogo from "../assets/images/svg-logo.png";
import footerImg01 from "../assets/images/footer-img-1.png";
import footerImg02 from "../assets/images/footer-img-2.png";
import footerImg03 from "../assets/images/footer-img-3.png";

import heroquestimg from "../assets/images/question-hero.png";
import blogimg from "../assets/images/blog-latest-article-bg.png";
import blogimg2 from "../assets/images/blog-img-2.png";

// card back

// icon

import { IoMdMenu, IoIosAdd } from "react-icons/io";
import {
  FaRegWindowClose,
  FaChevronDown,
  FaChevronCircleDown,
  FaArrowUp,
  FaChevronCircleRight,
  FaChevronCircleLeft,
  FaWallet,
  FaClock,
} from "react-icons/fa";
import { CiLogin } from "react-icons/ci";
import { RiShoppingBasketFill } from "react-icons/ri";

function Home() {
  const navigate = useNavigate();

  const [productCardNumber, setProductCardNumber] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [blogData, setBlogData] = useState(null);
  const [cartData, setCartData] = useState(null);

  // لیست محصولات بخش انتخاب گیفت کارت

  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://steelblue-ferret-758809.hostingersite.com/api/categories',
        headers: {},
      }

      try {
        const response = await axios.request(config);
        // Assuming the response.data.categories is the array of cards
        setCards(response.data.data.categories || []); // Ensure the data is an array
      } catch (error) {
        console.log(error);
        setCards([]); // Set cards to an empty array in case of error
      }
    };

    fetchData();
  }, []);

  // ---------------------------------------------

  //  خرید گیفت کارت دراپ داون اول

  const [categories, setCategories] = useState([]);
  const [selectedValueId, setSelectedValueId] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState({
    title: "",
    image: "",
  });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [selectedHomeDrop, setSelectedHomeDrop] = useState(null);

  useEffect(() => {
    if (selectedCategory.id) {
      const fetchCategoryInfo = async () => {
        try {
          const response = await axios.get(
            `https://steelblue-ferret-758809.hostingersite.com/api/categories/info/${selectedCategory.id}`,
            {
              headers: { Accept: 'application/json' },
            }
          )
          setSelectedTitle(response.data.data);
        } catch (error) {
          console.error("Error fetching category info:", error);
        }
      };

      fetchCategoryInfo();
    }
  }, [selectedCategory]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedCountry(null);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };

  const getCurrencySymbol = (countryTitle) => {
    switch (countryTitle) {
      case "آمریکا":
        return "دلار";
      case "آلمان":
        return "یورو";
      case "انگلیس":
        return "پوند";
      default:
        return "";
    }
  };

  const calculatePrice = () => {
    if (selectedTitle && selectedCountry) {
      if (selectedTitle.type === "gift_card") {
        const selectedGiftCard = selectedCountry.giftCardRows.find(
          (row) => row.id === selectedValueId
        );
        return selectedGiftCard ? selectedGiftCard.price : 0;
      } else if (selectedTitle.type === "license") {
        const selectedLicense = selectedCountry.licenses.find(
          (license) => license.id === selectedValueId
        );
        return selectedLicense ? selectedLicense.price : 0;
      }
    }
    return 0;
  };

  // ---------------------------------------------

  // فراخوانی اطلاعات دسته بندی کارت ها

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          'https://steelblue-ferret-758809.hostingersite.com/api/categories'
        )
        setCategories(response.data.data.categories);

        // Set the default category to the first item in the response
        if (response.data.data.categories.length > 0) {
          setSelectedCategory(response.data.data.categories[0]);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const accessToken = sessionStorage.getItem("access_token");
    setIsLoggedIn(!!accessToken);
  }, []);

  // تعداد محصولات در سبد خرید

  const fetchCartData = async () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://api.zoodgift.com/api/cart",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    };

    try {
      const response = await axios.request(config);
      if (response.status === 404 || response.data.statusCode === 404) {
        // Handle empty cart case
      } else {
        setCartData(response.data);
        setProductCardNumber(response.data.data.products.length);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error("سبد محصول شما خالی است");
      } else {
        toast.error("خطا در دریافت داده‌های سبد خرید");
      }
      console.log(error);
    }
  };

  // برای نمایش در لود اولیه
  useEffect(() => {
    fetchCartData();
  }, []);

  // هندل هدایت کردن به صفحه اطلاعات محصول

  const handleCardClick = (id) => {
    console.log("Selected Card ID:", id);
    window.location.href = `/product-detail?id=${id}`;
  };

  // لیست پست های وبلاگ

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://steelblue-ferret-758809.hostingersite.com/api/blog/posts',
      headers: {},
    }

    axios
      .request(config)
      .then((response) => {
        const data = response.data.data;
        setPosts(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const formatDate = (dateString) => {
    if (!dateString) return "نا مشخص";
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("fa-IR", options);
  };

  const [loading, setLoading] = useState(true);

  // اضافه به سبد خرید

  const handleBuy = () => {
    if (!selectedHomeDrop) {
      console.error("No item selected");
      return;
    }

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://steelblue-ferret-758809.hostingersite.com/api/cart/add/${selectedHomeDrop}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
      data: '',
    }

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          toast.success("به سبد خرید اضافه شد");
          fetchCartData(); // فراخوانی مجدد برای آپدیت سبد خرید
        } else {
          toast.error("خطایی رخ داده است");
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 404 &&
          error.response.data.message === "Unauthenticated."
        ) {
          toast.error("برای دیدن جزئیات لطفا وارد شوید ");
        } else {
          console.log(error);
        }
      });
  };

  // هندل خرید برای آیکون کارت محصول

  // تبدیل اعداد به فارسی
  const toPersianDigits = (num) => {
    const persianDigits = "۰۱۲۳۴۵۶۷۸۹";
    return num.toString().replace(/\d/g, (digit) => persianDigits[digit]);
  };

  //

  // هندل رفتن به سبد خرید
  const handleBasketClick = () => {
    navigate("/card-page");
  };

  // هندل رفتن به داشبورد
  const handleProfileClick = (event) => {
    event.preventDefault(); // جلوگیری از رفتار پیش فرض لینک
    const accessToken = sessionStorage.getItem("access_token");
    if (accessToken) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  // اسلایدر بخش نمایش پست وبلاگ

  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 3;

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + itemsPerPage < posts.length
        ? prevIndex + itemsPerPage
        : prevIndex
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - itemsPerPage >= 0 ? prevIndex - itemsPerPage : prevIndex
    );
  };

const visiblePosts = Array.isArray(posts)
  ? posts.slice(currentIndex, currentIndex + itemsPerPage)
  : []

  // اجرای لودینگ

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  // ---------------
  return (
    <div className='body-white-color-2'>
      {/* <!--header-top--> */}
      <div className='header-top-main-wrapper'>
        {/* <!--input-for-controlling-the-functionality--> */}
        <input
          type='checkbox'
          className='mobile-nav-input mobile-nav-input-v2'
          id='mobile-nav-check'
          hidden
        />
        <div className='header-top-wrapper'>
          {/* <!--setting-contaienr--> */}
          <div className='container'>
            {/* <!--navigation--> */}
            <nav className='navigation-v2'>
              {/* <!--logo--> */}
              <a href='./' className='nav-v2-logo-wrapper'>
                {/* <!--img--> */}
                <img src={logo} alt='Logo' className='nav-v2-logo' />
              </a>
              {/* <!--list--> */}
              <ul className='nav-v2-list'>
                {/* <!--item--> */}
                <li className='nav-v2-item'>
                  <a href='./product-page.html' className='nav-v2-item-link'>
                    لیست گیفت کارت
                  </a>
                </li>
                {/* <!--item--> */}
                <li className='nav-v2-item'>
                  <a href='/teach' className='nav-v2-item-link'>
                    آموزش استفاده گیفت کارت
                  </a>
                </li>
                {/* <!--item--> */}
                <li className='nav-v2-item'>
                  <a href='/blog-category' className='nav-v2-item-link'>
                    بلاگ
                  </a>
                </li>
                {/* <!--item--> */}
                <li className='nav-v2-item'>
                  <a href='/contact' className='nav-v2-item-link'>
                    تماس با ما
                  </a>
                </li>
              </ul>
              {/* <!--==mobile-nav==--> */}
              {/* <!--open-icon--> */}
              <label
                htmlFor='mobile-nav-check'
                className='mobile-nav-open-label mobile-nav-btns'
              >
                <IoMdMenu className='fa-solid fa-bars open-nav-icon' />
              </label>

              {/* <!--buttons--> */}
              <div className='nav-v2-links-wrapper'>
                <div className='shop-link-v2-wrapper'>
                  {/* <!--number--> */}
                  <span className='shop-link-number'>
                    {productCardNumber.toLocaleString('fa-IR')}
                  </span>
                  {/* <!--link(Shop-link)--> */}
                  <a href='/card-page' className='nav-v2-link nav-v2-shop-link'>
                    {/* <!--icon--> */}

                    <RiShoppingBasketFill
                      className='fa-solid fa-basket-shopping'
                      onClick={handleBasketClick}
                    />
                  </a>
                </div>
                {/* <!--link(login-link)--> */}
                <a
                  href='/'
                  className='nav-v2-link nav-v2-Login-link'
                  onClick={handleProfileClick}
                >
                  {isLoggedIn ? 'داشبورد' : 'ورود به حساب'}
                </a>
                {/* <!--login--> */}
                <a
                  href='./login.html'
                  className='nav-login-btn nav-v2-responsive-login'
                >
                  <span className='login-text'>ورود</span>

                  <CiLogin className='fa-solid fa-arrow-right-to-bracket nav-login-icon' />
                </a>
              </div>
            </nav>
          </div>
        </div>
        {/* <!--nav-list--> */}
        <ul className='mobile-nav-list'>
          {/* <!--close-icon--> */}
          <label
            htmlFor='mobile-nav-check'
            className='mobile-nav-close-label mobile-nav-btns'
          >
            <FaRegWindowClose className='fa-solid fa-x open-close-icon' />
          </label>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./index-2.html' className='mobile-nav-link'>
              خانه
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./product-page.html' className='mobile-nav-link'>
              گیفت کارت
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./teach-page.html' className='mobile-nav-link'>
              آموزش استفاده گیفت کارت
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./error-404.html' className='mobile-nav-link'>
              راهنمای خرید گیفت کارت
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='/blog-category' className='mobile-nav-link'>
              بلاگ
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className='mobile-nav-item'>
            <a href='./error-404.html' className='mobile-nav-link'>
              تماس با ما
            </a>
          </li>
        </ul>
      </div>

      {/* <!--==========Header========--> */}
      <header className='header-v2' id='header-v2'>
        {/* <!--header-banner--> */}
        <div className='header-v2-banner'>
          {/* <!--setting-container--> */}
          <div className='container'>
            {/* <!--main-wrapper--> */}
            <div className='banner-content-wrapper  banner-content-wrapper-v2'>
              {/* <!--right-content--> */}
              <div className='banner-right-content banner-right-content-v2'>
                {/* <!--right-content-wrapper--> */}
                <div className='banner-right-content-wrapper'>
                  {/* <!--banner-v2-text-box--> */}
                  <div className='banner-v2-text-box'>
                    {/* <!--title--> */}
                    <h1 className='banner-title'>
                      {/* <!--sub-title--> */}
                      <span className='banner-sub-title'>زود گیفت</span>
                      {/* <!--main-title--> */}
                      <span className='banner-main-title'>
                        مرجع خرید گیفت کارت قانونی ارزان
                      </span>
                    </h1>
                    {/* <!--text--> */}
                    <p className='banner-v2-text'>
                      گیفت کارت‌ها giftcard کارت‌های اعتباری پیش‌پرداخت هستند که
                      می‌توان از آنها برای خرید کالاها و خدمات استفاده کرد. این
                      کارت‌ها توسط شرکت‌ها، فروشگاه‌ها و برندهای مختلف ارائه
                      می‌شوند و به صورت فیزیکی یا دیجیتالی عرضه می‌گردند. انواع
                      گیفت کارت‌ها شامل گیفت کارت‌های اپل، گوگل پلی، آمازون،
                      پلی‌استیشن، ایکس‌باکس و بسیاری دیگر است.
                    </p>
                    {/* <!--btns-wrapper--> */}
                    <div className='buttons-wrapper-v4'>
                      {/* <!--link--> */}
                      <a href='#' className='primary-btns primary-btn-1'>
                        خرید گیفت کارت
                      </a>
                      {/* <!--link--> */}
                      <a href='#' className='primary-btns primary-btn-2'>
                        راهنمای خرید
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--left-content--> */}
              <div className='banner-left-content banner-left-content-v2'>
                {/* <!--gift-card--> */}
                <div className='banner-gift-card'>
                  {/* <!--title--> */}
                  <h2 className='gift-card-main-title'>خرید گیفت کارت</h2>
                  {/* <!--underline--> */}
                  <span className='gift-card-underline'></span>
                  {/* <!--gift-card-content--> */}
                  <div className='gift-card-content'>
                    {/* <!--inputs for controlling the functionality--> */}
                    <input
                      type='checkbox'
                      id='checkbox-1'
                      className='gift-drop-btn-input type-input'
                      hidden
                    />
                    <input
                      type='checkbox'
                      id='checkbox-2'
                      className='gift-drop-btn-input value-input'
                      hidden
                    />
                    <input
                      type='checkbox'
                      id='checkbox-3'
                      className='gift-drop-btn-input region-input'
                      hidden
                    />
                    {/* <!--==gift-card-type===--> */}
                    <div className='gift-card-type-wrapper gift-card-groups'>
                      <h3 className='gift-card-title'>نوع گیفت کارت</h3>
                      {/* <!--drop-btn--> */}
                      <label
                        htmlFor='checkbox-1'
                        className='gift-card-drop-btn type-drop-btn'
                      >
                        {/* <!--text--> */}
                        <p className='gift-card-btn-text'>
                          {selectedCategory.title}
                        </p>
                        {/* <!--img and arrow--> */}
                        <div className='gift-card-icons-wrapper'>
                          {/* <!--icon--> */}
                          <FaChevronDown className='fa-solid fa-chevron-down gift-card-btn-icon' />
                          {/* <!--image--> */}
                          <img
                            src={selectedCategory.image}
                            alt='logo'
                            className='gift-card-logo'
                          />
                        </div>
                        {/* <!--gift-drop-list--> */}
                        <ul className='gift-drop-list type-drop-list'>
                          {categories.map((category) => (
                            <li
                              key={category.id}
                              className='gift-drop-item'
                              onClick={() => handleCategorySelect(category)}
                            >
                              <p className='gift-card-btn-text'>
                                {category.title}
                              </p>
                              <div className='gift-card-icons-wrapper'>
                                <img
                                  src={category.image}
                                  alt='logo'
                                  className='gift-card-logo'
                                />
                              </div>
                            </li>
                          ))}
                        </ul>
                      </label>
                    </div>
                    {/* <!--value and region wrapper--> */}
                    <div className='gift-card-bottom-drop-btns-wrapper'>
                      {/* <!--==gift-card-price===--> */}
                      <div className='gift-card-value-wrapper gift-card-bottom-groups'>
                        <h3 className='gift-card-title'>مقدار گیفت کارت</h3>
                        {/* <!--drop-btn--> */}
                        <label
                          htmlFor='checkbox-2'
                          className='gift-card-drop-btn'
                        >
                          {/* <!--text--> */}
                          <p className='gift-card-btn-text'>انتخاب مقدار</p>
                          {/* <!--img and arrow--> */}
                          <div className='gift-card-icons-wrapper'>
                            {/* <!--icon--> */}
                            <FaChevronDown className='fa-solid fa-chevron-down gift-card-btn-icon' />
                          </div>
                          {/* <!--gift-drop-list--> */}
                          {selectedTitle &&
                            selectedTitle.type === 'gift_card' &&
                            selectedCountry && (
                              <ul className='gift-drop-list'>
                                {selectedCountry.giftCardRows.map((row) => (
                                  <li
                                    key={row.id}
                                    className='gift-drop-item'
                                    onClick={() => {
                                      setSelectedValueId(row.id)
                                      setSelectedHomeDrop(row.product_id)
                                    }}
                                  >
                                    <p className='gift-card-btn-text'>{`${
                                      row.balance
                                    } ${getCurrencySymbol(
                                      selectedCountry.title
                                    )}`}</p>
                                  </li>
                                ))}
                              </ul>
                            )}

                          {selectedTitle &&
                            selectedTitle.type === 'license' &&
                            selectedCountry && (
                              <ul className='gift-drop-list'>
                                {selectedCountry.licenses.map(
                                  (license, index) => (
                                    <li
                                      key={index}
                                      className='gift-drop-item'
                                      onClick={() => {
                                        setSelectedValueId(license.id)
                                        setSelectedHomeDrop(license.product_id)
                                      }}
                                    >
                                      <p className='gift-card-btn-text'>{`${license.duration} ماه`}</p>
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                        </label>
                      </div>
                      {/* <!--==gift-card-region===--> */}
                      <div className='gift-card-region-wrapper gift-card-bottom-groups'>
                        <h3 className='gift-card-title'>ریجن گیفت کارت</h3>
                        {/* <!--drop-btn--> */}
                        <label
                          htmlFor='checkbox-3'
                          className='gift-card-drop-btn'
                        >
                          {/* <!--text--> */}
                          <p className='gift-card-btn-text'>انتخاب ریجن</p>
                          {/* <!--img and arrow--> */}
                          <div className='gift-card-icons-wrapper'>
                            {/* <!--icon--> */}
                            <FaChevronDown className='fa-solid fa-chevron-down gift-card-btn-icon' />
                          </div>
                          {/* <!--gift-drop-list--> */}
                          {selectedTitle && (
                            <ul className='gift-drop-list'>
                              {selectedTitle.countires.map((country, index) => (
                                <li
                                  key={index}
                                  className='gift-drop-item'
                                  onClick={() => handleCountrySelect(country)}
                                >
                                  {/* <!--text--> */}
                                  <p className='gift-card-btn-text'>
                                    {country.title}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          )}
                        </label>
                      </div>
                    </div>
                    {/* <!--Gift-card-price--> */}
                    <p className='gift-card-price'>
                      قیمت :
                      <span className='price-number'>
                        {toPersianDigits(calculatePrice().toLocaleString())}{' '}
                      </span>
                      تومان
                    </p>
                    {/* <!--buy-btn--> */}
                    <button
                      className='gift-card-buy-btn gift-card-v2-buy-btn'
                      onClick={handleBuy}
                    >
                      <span className='gift-card-buy-btn-text'>خوبه میخرم</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <!--===========Main=========--> */}
      <main className='main'>
        {/* <!--==========benefits-section=========--> */}
        <section className='benefits-section'>
          {/* <!--bacground---> */}
          <img
            src={benefitImg}
            alt='background-img'
            className='benfits-background'
          />
          {/* <!--main-wrapper--> */}
          <div className='benefites-content-wrapper'>
            {/* <!--setting-container--> */}
            <div className='container'>
              {/* <!--box-wrapper--> */}
              <div className='benefits-box-wrapper'>
                {/* <!--box--> */}
                <div className='benfits-box'>
                  {/* <!--benfits-step-box--> */}
                  <div className='benefits-step-box'>
                    {/* <!--text--> */}
                    <p className='benefits-step-text'>مرحله اول</p>
                  </div>
                  {/* <!--text-box--> */}
                  <div className='benefits-text-box'>
                    {/* <!--title--> */}
                    <h4 className='benfits-title'>انتخاب گیفت کارت</h4>
                    <p className='benefits-text'>
                      گیفت کارت مورد نظر خود را انتخاب کنید
                    </p>
                  </div>
                </div>
                {/* <!--box--> */}
                <div className='benfits-box'>
                  {/* <!--benfits-step-box--> */}
                  <div className='benefits-step-box'>
                    {/* <!--text--> */}
                    <p className='benefits-step-text'>مرحله دوم</p>
                  </div>
                  {/* <!--text-box--> */}
                  <div className='benefits-text-box'>
                    {/* <!--title--> */}
                    <h4 className='benfits-title'>پرداخت امن</h4>
                    <p className='benefits-text'>
                      بعد از تکمیل سفارش وارد حساب کاربری خود شوید سپس پرداخت
                      محصول مورد نظر را انجام دهید
                    </p>
                  </div>
                </div>
                {/* <!--box--> */}
                <div className='benfits-box'>
                  {/* <!--benfits-step-box--> */}
                  <div className='benefits-step-box'>
                    {/* <!--text--> */}
                    <p className='benefits-step-text'>مرحله سوم</p>
                  </div>
                  {/* <!--text-box--> */}
                  <div className='benefits-text-box'>
                    {/* <!--title--> */}
                    <h4 className='benfits-title'>تحویل آنی</h4>
                    <p className='benefits-text'>
                      کد گیفت کارت رو بلافاصله پس از پرداخت از طریق پیامک و
                      ایمیل دریافت میکنید ، به همین راحتی
                    </p>
                  </div>
                </div>
                {/* <!--logo--> */}
                <div className='benefits-logo-wrapper'>
                  {/* <!--img--> */}
                  <img src={logo02} alt='logo' className='benefits-logo' />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--==========card-section============--> */}
        <section className='card-section card-section-v2' id='gift-list'>
          {/* <!--setting-container--> */}
          <div className='container'>
            {/* <!--title--> */}
            <h2 className='h2-title card-title'>
              {/* <!--icon--> */}
              <FaWallet className='fa-solid fa-wallet card-title-icon' />
              انتخاب گیفت کارت
            </h2>
            {/* <!--main-wrapper--> */}
            <div className='card-section-content-wrapper'>
              {cards.length > 0 ? (
                cards.map((card) => (
                  <div
                    className='card-link'
                    key={card.en_title}
                    onClick={() => handleCardClick(card.id)}
                  >
                    <div className='card'>
                      <img
                        src={cardBack}
                        alt='background-img'
                        className='card-background-img'
                      />
                      <div className='card-content'>
                        <img
                          src={card.image}
                          alt='gift-card-type'
                          className='card-img'
                        />
                        <h4 className='card-text'>
                          <span className='card-title'>{card.title}</span>
                          <span className='card-title-eng'>
                            {card.en_title}
                          </span>
                        </h4>
                      </div>
                      <div className='card-bottom-text-box card-bottom-text-box-main'>
                        <p className='card-bottom-text'>
                          <FaClock className='fa-solid fa-clock blog-gift-card-icon' />

                          {card.type === 'gift_card'
                            ? 'تحویل آنی'
                            : card.type === 'license'
                            ? 'تحویل تایم دار'
                            : ''}
                        </p>
                        <div
                          className='card-add-icon'
                          title='افزودن به سبد خرید'
                        >
                          <IoIosAdd className='fa-solid fa-plus card-add-icon-content' />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        </section>

        {/* <!--==========blog-section========--> */}
        <section className='blog-section'>
          {/* <!--setting-container--> */}
          <div className='container'>
            {/* <!--main-wrapper--> */}
            <div className='blog-section-content-wrapper'>
              {/* <!--title-box--> */}
              <div className='blog-title-box'>
                {/* <!--title--> */}
                <h2 className='h2-title blog-title'>
                  <span className='h2-main-title'>
                    {/* <!--icon--> */}
                    <i className='fa-regular fa-file-lines blog-title-icon'></i>
                    {/* <!--text--> */}
                    وبلاگ زود گیفت
                  </span>
                  {/* <!--sub-title--> */}
                  <p className='h2-sub-title'>
                    در وبلاگ زود گیفت، شما به گنجینه‌ای از اخبار و مطالب آموزشی
                    به‌روز و معتبر دسترسی خواهید داشت
                  </p>
                </h2>
                {/* <!--see-all-btn--> */}
                <a href='./error-404.html' className='see-all-btn'>
                  مشاهده همه
                  <FaChevronCircleLeft className='fa-solid fa-circle-chevron-left see-all-btn-icon' />
                </a>
              </div>
              {/* <!--slider-buttons-wrapper--> */}
              <div className='slider-buttons-wrapper-v3'>
                {/* <!--slider-buttons--> */}
                <button className='slider-btn-v3'>
                  {/* <!--icon--> */}
                  <FaChevronCircleRight
                    className='fa-solid fa-circle-chevron-right'
                    onClick={handlePrev}
                  />
                </button>
                {/* <!--slider-buttons--> */}
                <button className='slider-btn-v3'>
                  {/* <!--icon--> */}
                  <FaChevronCircleLeft
                    className='fa-solid fa-circle-chevron-right'
                    onClick={handleNext}
                  />
                </button>
              </div>
              {/* <!--blog-boxes-wrapper--> */}
              <div className='blog-boxes-main-wrapper'>
                {/* <!--slider--> */}
                <div className='slider-x blog-boxes-wrapper'>
                  {/* <!--box--> */}
                  {visiblePosts.map((post, index) => (
                    <a href='./blog.html' className='blog-box-link' key={index}>
                      <div className='blog-box'>
                        <img
                          src={post.image || 'default-image.jpg'}
                          alt='blog-img'
                          className='blog-img'
                        />
                        <h5 className='h5-title'>{post.title}</h5>
                        <div className='blog-box-down-wrapper'>
                          <p className='blog-text'>
                            <i className='fa-solid fa-table-cells'></i>
                            آموزشی
                          </p>
                          <p className='blog-text'>
                            <i className='fa-solid fa-calendar-days'></i>
                            {formatDate(post.created_at)}
                          </p>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
                {/* <!--box--> */}
                <a href='./blog.html' className='blog-page-link'>
                  <div className='blog-see-article-box'>
                    {/* <!--img--> */}
                    <img
                      src={blogimg}
                      alt='latest-article'
                      className='blog-see-article-img'
                    />
                    {/* <!---content--> */}
                    <div className='blog-see-article-content'>
                      {/* <!--img--> */}
                      <img
                        src={blogimg2}
                        alt='img'
                        className='blog-see-article-img-2'
                      />
                      {/* <!--text--> */}
                      <p className='blog-see-article-text'>آخرین مقالات</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* <!--===========info-section-v2-title-and-img==============--> */}
        <section className='info-section-v2-title-and-img'>
          {/* <!--setting-container--> */}
          <div className='smaller-container'>
            {/* <!--title--> */}
            <h3 className='h3-title'>
              {/* <!--h3-sub-title--> */}
              <span className='h3-title-sub-title'>
                با انواع گیفت کارت آشنا شوید
              </span>
              گیفت کارت چیست و کاردبرد آن چیست ؟
            </h3>
            {/* <!--info-secton-banner--> */}
            <img src={giftlearn} alt='img' className='info-banner-v2 ' />
          </div>
        </section>

        {/* <!--==========info-section========--> */}
        <section className='info-section-v2'>
          {/* <!--info-section-background-img--> */}
          <img
            src='./assets/images/info-v2-background-img.png'
            alt='Logo'
            className='info-section-v2-background-img'
          />
          {/* <!--setting-container--> */}
          <div className='smaller-container'>
            {/* <!--text--> */}
            <p className='info-text-v2'>
              کاربرد گیفت کارت‌ها بسیار متنوع است. از آنها می‌توان برای خرید
              اپلیکیشن‌ها، بازی‌ها، موسیقی، فیلم و کتاب‌های الکترونیکی استفاده
              کرد. همچنین می‌توانند به عنوان هدیه به دوستان و خانواده داده شوند
              یا به عنوان جایزه در مسابقات و رویدادها استفاده شوند. گیفت کارت‌ها
              راهی ساده و موثر برای مدیریت هزینه‌ها و همچنین ارائه هدیه‌ای
              کاربردی و محبوب به دیگران هستند.
            </p>
          </div>
        </section>

        <section className='your-question-section your-question-section-v2'>
          <div className='smaller-container'>
            <div className='your-question-main-wrapepr'>
              <div className='your-question-right-side'>
                <div className='h2-title'>سوالات متدوال شما</div>
                <input
                  type='checkbox'
                  className='question-input'
                  id='question-input-1'
                />
                <label
                  htmlFor='question-input-1'
                  className='question-label question-label-v2'
                >
                  <h5 className='question-text'>
                    امکان بازگرداندن یا تعویض گیفت کارت خریداری شده وجود دارد؟
                    <FaChevronCircleDown
                      fa-solid
                      fa-circle-chevron-down
                      question-icon
                    />
                  </h5>
                  <p className='question-answer'>
                    خیر، گیفت کارت یک محصول دیجیتال است که بلافاصله پس از دریافت
                    قابل استفاده است. بنابراین، پس از تحویل، امکان بازگرداندن یا
                    تعویض آن وجود ندارد.
                  </p>
                </label>
                <input
                  type='checkbox'
                  className='question-input'
                  id='question-input-2'
                />
                <label
                  htmlFor='question-input-2'
                  className='question-label question-label-v2'
                >
                  <h5 className='question-text'>
                    چگونه از زود گیفت ، گیفت کارت خریداری کنیم؟
                    <FaChevronCircleDown
                      fa-solid
                      fa-circle-chevron-down
                      question-icon
                    />
                  </h5>
                  <p className='question-answer'>
                    برای خرید گیفت کارت از زود گیفت ، ابتدا باید در سایت ثبت‌نام
                    کنید و سپس سفارش خود را انجام دهید .
                  </p>
                </label>
                <input
                  type='checkbox'
                  className='question-input'
                  id='question-input-3'
                />
                <label
                  htmlFor='question-input-3'
                  className='question-label question-label-v2'
                >
                  <h5 className='question-text'>
                    کد گیفت کارت به کجا ارسال می‌شود؟
                    <FaChevronCircleDown
                      fa-solid
                      fa-circle-chevron-down
                      question-icon
                    />
                  </h5>
                  <p className='question-answer'>
                    کد گیفت کارت از طریق پیامک و ایمیل برای شما ارسال می‌شود.
                    همچنین می‌توانید آن را در بخش "پیگیری سفارش" - "سفارشات من"
                    در پنل حساب کاربری خود مشاهده کنید.
                  </p>
                </label>
                <input
                  type='checkbox'
                  className='question-input'
                  id='question-input-4'
                />
                <label
                  htmlFor='question-input-4'
                  className='question-label question-label-v2'
                >
                  <h5 className='question-text'>
                    مدت زمان تحویل گیفت کارت‌ها چقدر است؟
                    <FaChevronCircleDown
                      fa-solid
                      fa-circle-chevron-down
                      question-icon
                    />
                  </h5>
                  <p className='question-answer'>
                    تحویل گیفت کارت‌ها به صورت فوری انجام می‌شود و از طریق ایمیل
                    برای شما ارسال می‌گردد.
                  </p>
                </label>
              </div>
              <div className='your-question-left-side'>
                <img
                  src={heroquestimg}
                  alt='qustion-hero'
                  className='question-hero-img'
                />
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* <!--=========Footer==============--> */}
      <footer className='footer-v2 '>
        {/* <!--logo--> */}
        <img src={footerlogo} alt='logo' className='footer-logo' />
        {/* <!--===setting-container===--> */}
        <div className='bigger-container'>
          {/* <!--footer-content-wrapper--> */}
          <div className='footer-content-wrapper-v2'>
            {/* <!--footer-top-wrapper--> */}
            <div className='footer-top-wrapper-v2'>
              {/* <!--right-side--> */}
              <div className='footer-top-right-wrapper-v2'>
                {/* <!--title--> */}
                <h5 className='footer-row-title footer-row-title-v2'>
                  خدمات ما
                </h5>
                <div className='footer-list-wrapper-v2'>
                  {/* <!--list--> */}
                  <ul className='footer-list footer-list-v2'>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید گیفت کارت اپل
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید گیفت کارت پلی استیشن
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید گیفت کارت استیم{' '}
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید گیفت کارت ایکس باکس
                      </a>
                    </li>
                  </ul>
                  {/* <!--list--> */}
                  <ul className='footer-list footer-list-v2'>
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید گیفت کارت گوگل پلی
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید گیفت کارت پلی استیشن پلاس
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید یوسی پابجی{' '}
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید جم کلش اف کلنز
                      </a>
                    </li>
                  </ul>
                  {/* <!--list--> */}
                  <ul className='footer-list footer-list-v2'>
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید اشتراک پریمیوم تلگرام
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید اشتراک اسپاتیفای
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید لایسنس ادوبی{' '}
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        خرید ویباکس فورتنایت
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='footer-top-left-wrapper-v2'>
                {/* <!--title--> */}
                <h5 className='footer-row-title footer-row-title-v2'>
                  دسترسی سریع
                </h5>
                <div className='footer-list-wrapper-v2'>
                  {/* <!--list--> */}
                  <ul className='footer-list footer-list-v2'>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./blog.html' className='footer-item-link'>
                        بلاگ زود گیفت{' '}
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        شرایط و قوانین خرید{' '}
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        حساب کاربری
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className='footer-item'>
                      <a href='./error-404.html' className='footer-item-link'>
                        تماس با ما
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!--footer-middle-wrapper--> */}
            <div className='footer-middle-wrapper-v2'>
              {/* <!--phone-number-box--> */}
              <div className='footer-phone-number-box'>
                {/* <!--title--> */}
                <h5 className='footer-row-title'>اطلاعات تماس</h5>
                {/* <!--footer-middle-text--> */}
                <p className='footer-middle-text'>
                  <a href='./error-404.html' className='middle-text-link'>
                    ۰۶۱۵۲۳۳۳۷۹۴
                  </a>{' '}
                  |{' '}
                  <a href='./error-404.html' className='middle-text-link'>
                    ۰۹۱۶۰۲۶۵۶۶۱
                  </a>
                </p>
              </div>
              {/* <!--socila-box--> */}
              <div className='footer-social-box'>
                {/* <!--title--> */}
                <h5 className='footer-row-title'>زود گیفت در شبکه اجتماعی</h5>
                {/* <!--footer-middle-text--> */}
                <p className='footer-middle-text-social'>
                  <a href='./error-404.html' className='middle-text-link'>
                    تلگرام
                  </a>
                  <a href='./error-404.html' className='middle-text-link'>
                    اینستاگرام
                  </a>
                  <a href='./error-404.html' className='middle-text-link'>
                    یوتیوب
                  </a>
                </p>
              </div>
            </div>
            {/* <!--footer-bottom-wrapper--> */}
            <div className='footer-bottom-wrapper'>
              {/* <!--footer-row--> */}
              <div className='footer-row footer-big-row-v2'>
                {/* <!--title--> */}
                <h5 className='footer-row-title-v2 footer-row-title'>
                  تجربه‌ای متفاوت در خرید گیفت کارت
                </h5>
                {/* <!--text--> */}
                <p className='footer-text footer-text-v2'>
                  تجربه‌ای متفاوت از خرید گیفت کارت را با ما تجربه کنید. با
                  زودگیفت، می‌توانید به راحتی انواع کارت‌های هدیه محبوب از جمله
                  گیفت کارت آیتونز، گوگل پلی، پلی استیشن، ایکس باکس، اسپاتیفای،
                  استیم، تلگرام، پابجی موبایل، گیم پس، مسترکارت و بسیاری دیگر را
                  خریداری کنید. با ما، لذت یک خرید آسان و سریع را تجربه کنید و
                  از پشتیبانی حرفه‌ای و مطمئن بهره‌مند شوید.
                </p>
              </div>
              {/* <!--footer-row--> */}
              <div className='footer-row footer-logo-row footer-logo-row-v2'>
                {/* <!--title--> */}
                <h5 className='footer-row-title'>نماد اعتماد </h5>
                {/* <!--imgs-wrapper--> */}
                <div className='footer-row-img-wrapper'>
                  {/* <!--img-box--> */}
                  <div className='footer-img-box'>
                    {/* <!--img--> */}
                    <img
                      src={footerImg01}
                      alt='footer-img'
                      className='footer-img'
                    />
                  </div>
                  {/* <!--img-box--> */}
                  <div className='footer-img-box'>
                    {/* <!--img--> */}
                    <img
                      src={footerImg02}
                      alt='footer-img'
                      className='footer-img'
                    />
                  </div>
                  {/* <!--img-box--> */}
                  <div className='footer-img-box'>
                    {/* <!--img--> */}
                    <img
                      src={footerImg03}
                      alt='footer-img'
                      className='footer-img'
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <!--copy-right-wrapper--> */}
            <div className='copy-right-wrapper-v2'>
              {/* <!--right-side--> */}
              <p className='copy-right-right-text'>
                {/* <!--button--> */}
                <a href='#header-v2' className='moving-top-button'>
                  {/* <!--icon--> */}

                  <FaArrowUp className='fa-solid fa-arrow-up moving-top-icon' />
                </a>
                کلیه حقوق مادی و معنوی این سایت متعلق به فروشگاه زود گیفت
                می‌باشد
              </p>
              {/* <!--left-side--> */}
              <div className='copy-right-left-side-wrapper'>
                {/* <!--logo-wrapper--> */}
                <div className='copy-right-logo-wrapper'>
                  <img src={logo02} alt='logo' className='copy-right-logo' />
                </div>
                {/* <!--text-box---> */}
                <div className='copy-right-left-side-text-box'>
                  {/* <!--text--> */}
                  <p className='copy-right-left-main-text'>
                    © 2024 ZOODGIFT.COM
                  </p>
                  {/* <!--text--> */}
                  <p className='copy-right-left-sub-text'>
                    All material and intellectual rights of this site belong to
                    ZoodGift store
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* اعلان ها  */}
      <ToastContainer toastClassName='toast-right' />
    </div>
  )
}

export default Home;
