import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

import loginimg from '../assets/images/form-banner-img.png'

function Login() {
  const navigate = useNavigate()

  const [phoneOrEmail, setPhoneOrEmail] = useState('')
  const [password, setPassword] = useState('')

  // login handle
  const loginHandle = (event) => {
    event.preventDefault() // جلوگیری از ارسال فرم

    const data = JSON.stringify({
      phone: phoneOrEmail,
      password: password,
    })

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://steelblue-ferret-758809.hostingersite.com/api/login',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios
      .request(config)
      .then((response) => {
        if (response.data.statusCode === 200) {
          toast.success('با موفقیت وارد شدید', { className: 'toast-right' })
          sessionStorage.setItem(
            'access_token',
            response.data.data.access_token
          )
          setTimeout(() => {
            navigate('/')
          }, 5000) // 3000 میلی‌ثانیه معادل 3 ثانیه
        } else {
          toast.error(response.data.message, { className: 'toast-right' })
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || 'خطایی رخ داده است'
        toast.error(errorMessage)
        console.log(error)
      })
  }

  return (
    <div className='body-white-color-2'>
      <div className='container'>
        <div className='section-flex-wrapper'>
          <div className='form-v1-wrapper'>
            <form onSubmit={loginHandle} className='form-v1'>
              <div className='form-group-v1'>
                <h4 className='form-group-v1-title'>شماره موبایل یا ایمیل</h4>
                <input
                  type='text'
                  className='form-group-v1-input'
                  required
                  value={phoneOrEmail}
                  onChange={(e) => setPhoneOrEmail(e.target.value)}
                />
              </div>
              <div className='form-group-v1'>
                <h4 className='form-group-v1-title'>رمز عبور</h4>
                <input
                  type='password'
                  className='form-group-v1-input form-group-v1-password'
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <i className='fa-regular fa-eye form-v1-eye-icon'></i>
              </div>
              <div className='form-v1-link-wrapper'>
                <div className='remmeber-me'>
                  <input
                    type='checkbox'
                    id='remmeber-input'
                    className='remmeber-me-input'
                  />
                  <label htmlFor='remmeber-input' className='remmeber-me-text'>
                    من را به خاطر بسپار
                  </label>
                </div>
                <a href='./forgot-password.html' className='form-forgot-link'>
                  رمز عبور را فراموش کرده اید؟
                </a>
              </div>
              <button type='submit' className='form-v1-btn'>
                ورود به حساب کاربری
              </button>
              <p className='form-v1-text'>
                <i className='fa-solid fa-circle-exclamation form-v1-text-icon'></i>
                با ورود به ام ار ام قوانین و شرایط استفاده از ام ار ام را می
                پذیرم.
              </p>
              <p className='form-v1-text'>
                <i className='fa-solid fa-circle-exclamation form-v1-text-icon'></i>
                لطفا شماره موبایل که وارد می کنید به نام خودتان باشد
              </p>
            </form>
            <div className='form-v1-bottom-links-wrapper'>
              <a href='./index-2.html' className='form-v1-home-link'>
                <i className='fa-solid fa-house'></i>
                بازگشت به فروشگاه
              </a>
              <p className='form-v1-text form-v1-text-bottom'>
                مطمئن شوید که در دامنه mrm.gift هستید.
              </p>
            </div>
          </div>
          <div className='form-banner-v1'>
            <img src={loginimg} alt='img' className='form-banner-v1-content' />
          </div>
        </div>
      </div>
      <ToastContainer toastClassName='toast-right' />
    </div>
  )
}

export default Login
